import { Component, OnInit } from '@angular/core';
import { UserService } from './../shared/user.service';
import { User } from '../shared/user.model';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css']
})
export class DashboardAdminComponent implements OnInit {

  userDetails: User;
  refresh = 0;

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    // console.log("this.route.snapshot.paramMap.get('id')");
    // console.log(this.route.snapshot.paramMap.get('id'));

  //   setTimeout( () => {

  //     if ( this.route.snapshot.paramMap.get('id') === '1') {
  //       this.router.navigateByUrl('dashboard/admin/0');
  //  }

  //    }, 3000 );




    this.userService.getUserProfile().subscribe(
      res => {
        console.log(res['user']);
        if (res['user'].role[0].name !== 'adminE6kdonation') {
          this.userService.deleteToken();
          this.router.navigate(['/login']);
        }
        // this.userDetails = res['user'];
      },
      err => {
        console.log(err);
      }
    );

  }

//   delay(3000).then(any=>{
//     //your task after delay.
// });



}
