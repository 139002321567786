import { Transaction } from './../../shared/transaction.model';
import { Component, OnInit } from '@angular/core';
import { TransactionService } from './../../shared/transaction.service';
import { UserService } from './../../shared/user.service';
import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { PagerService } from './../../shared/pager.service';
import { OrganisationService } from '../../shared/organisation.service';
import { Organisation } from './../../shared/organisation.model';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {


  transactionArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  listTransaction: Boolean;
  currentTransaction: Transaction;
  errorMsg;
  validEmail;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  email: String = '';

  isEmailSended;
  isEmailNotSend;
  isEmailSending: Boolean = false;

  organization: Organisation;
  CURRENT_PAGE : string = "Transaction";
  constructor(private transactionService: TransactionService,
              private userService: UserService,
              private pagerService: PagerService,
              private organisationService: OrganisationService,
              private router: Router) { }

  ngOnInit() {
    this.userService.getUserProfile().subscribe(
      res => {
        this.getOrganisationByUserId(res['user'].idOrganisation);
        this.list(res['user'].idOrganisation);
      },
      err => {
        console.log(err);
      }
    );
  }

  getOrganisationByUserId(id) {
    this.organisationService.getOrganisationById(id).subscribe(
      res => {
          this.organization = res['organisation'];
      },
      err => {

      }
    );
  }

  list(id) {
    this.transactionService.getTransactionByOrganisation(id).subscribe(
      res => {
      //  this.transactionArray = res['transactions'].reverse();
      //  this.allItems =  res['transactions'].reverse();
      //  this.transactionArray.reverse();
      //  this.allItems.reverse();

      this.transactionArray = res['transactions'];
      this.allItems =  res['transactions'];

      this.transactionArray.reverse();

       if (this.allItems.length !== 0 ) {
         this.listTransaction = true;
       } else {
         this.listTransaction = false;
       }
       this.setPage(1);
      },
      err => {
        console.log(err);
      }
    );
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  searchCampaign(searchValue: string) {
    const campaignArray =  this.transactionArray.filter(x => x._id.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = campaignArray;
    this.setPage(1);
  }

 selectCurrentCampaign(transaction) {
    this.currentTransaction = transaction;
  }

  selectTransaction(transaction) {
    this.isEmailSending = false;
    this.isEmailNotSend = null;
    this.isEmailSended = null;
    this.validEmail = null;
    this.email = transaction['email'];
    this.currentTransaction = transaction;
    console.log(this.currentTransaction);
    this.currentTransaction['organizationName'] = this.organization.organisationName;
  }

  sendEmailReceipt() {

   if (this.emailRegex.test(String(this.email).toLowerCase()) === true) {
    this.validEmail = null;
    this.isEmailSending = true;
    // SEND EMAIL
    this.currentTransaction['email'] = this.email;
    this.transactionService.sendReceiptTransaction(this.currentTransaction).subscribe(
      res => {
        this.isEmailSending = null;
        this.isEmailSended = true;
      },
      err => {
        this.isEmailSending = null;
        this.isEmailNotSend = true;
      }
    );
   } else {
    this.validEmail = false;
    setTimeout(() => {
      this.validEmail = null;
    }, 3000);
   }

  }
}
