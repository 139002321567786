import {AbstractControl} from '@angular/forms';
import { FormGroup } from '@angular/forms';


// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
   return (formGroup: FormGroup) => {
       const control = formGroup.controls[controlName];
       const matchingControl = formGroup.controls[matchingControlName];

      //  if (matchingControl.errors && !matchingControl.errors.MatchPassword) {
      //      // return if another validator has already found an error on the matchingControl
      //      return;
      //  }

       if (  matchingControl.errors !== undefined &&  matchingControl.errors ) {
         // return if another validator has already found an error on the matchingControl
         return;
     }

       var mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

       var MatchingControlError = {
          structurePass : false,
          MatchPassword : false
       }

       var controlControlError = {
         structurePass : false,
         MatchPassword : false
      }

      

       if (control.value.length > 0 && mediumRegex.test(control.value) === false ) {
         controlControlError.structurePass = true;  
        
      } else if (matchingControl.value.length > 0 && matchingControl.value.length >= control.value.length && control.value !== matchingControl.value) {
         MatchingControlError.MatchPassword = true;        
      } else {        
         return null;
      }

      if (matchingControl.value.length > 0 && matchingControl.value.length >= control.value.length && control.value !== matchingControl.value) {
         MatchingControlError.MatchPassword = true;        
      }
      if(MatchingControlError.structurePass === true || MatchingControlError.MatchPassword === true){
         matchingControl.setErrors(MatchingControlError);
      }else{
         matchingControl.setErrors(null);
      }

      if(controlControlError.structurePass === true || controlControlError.MatchPassword === true){
         control.setErrors(controlControlError);
      }else{
         control.setErrors(null);
      }
 
      
    
   }
}



export class PasswordValidation {

     static MatchPassword(AC: AbstractControl) {



     let password = AC.get('password').value; // to get value in input tag
     let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag

     //var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
     //var strongRegex = new RegExp("/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/");
     //var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
       var mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
   //   console.log(mediumRegex.test(password));
   //   if (mediumRegex.test(password)) {
   //        console.log('no strong pass');
   //   }

     if (mediumRegex.test(password) === false ) {
        AC.get('confirmPassword').setErrors( { structurePass: true } );        
     } else if (password !== confirmPassword) {
        AC.get('confirmPassword').setErrors( { MatchPassword : true } );
     } else {        
        return null;
     }




      // if (password !== confirmPassword) {
      //   // AC.get('confirmPassword').setErrors( {MatchPassword: true, structurePass: false} );
      //   AC.get('confirmPassword').setErrors( {MatchPassword: true} );
      // }
      // else if (mediumRegex.test(password) === false ) {
      //   AC.get('confirmPassword').setErrors( {structurePass: true} );
      // }
      // else {
      //   console.log('true');
      //   return null;
      // }

      // if (password !== confirmPassword || strongRegex.test(password) === false) {
      //       console.log('false');
      //       AC.get('confirmPassword').setErrors( {MatchPassword: true, structurePass: true} );

      //   } else {
      //       console.log('true');
      //       return null
      //   }
    }
}
