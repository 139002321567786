import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

import { UserService } from '../../shared/user.service';

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {


  public formModel: FormModel = {};
private siteKey : string = '6LdbDbAZAAAAAEaTv6aWBzblaP6tlaA0iXqKCGp0';
private siteKey2 : string = '6LePbq4UAAAAAPqwJU8u5g1Of1TIEMyoPpJQpyaD';
key : string = "6LdbDbAZAAAAAHOID_0WA3dnRNh2ZjOURCuPyG7e";


  constructor(private userService: UserService, private router: Router) { }

  model = {
    email: '',
    password: ''
  };
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages: string;
  recaptcha : any[];
  captchaControl2 : boolean;
  
  ngOnDestroy(){

  }
  ngOnInit() {

    this.captchaControl2 = false;
    if (this.userService.isLoggedIn())
      this.router.navigateByUrl('/dashboard');


     
     
  }
  

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  onSubmit(form: NgForm) {
    // console.log(form);
    this.userService.login(form.value).subscribe(
      res => {
        // console.log(res['active']);
        this.userService.setToken(res['token']);
        console.log(res['role']);
        if (res['active'] === true) {
          this.router.navigateByUrl('/dashboard');
        } else if (res['active'] === false) {
          this.router.navigateByUrl('account/details');
        } else if (res['role'] === 'adminE6kdonation') {
          this.router.navigateByUrl('dashboard/admin');
        } else {
          this.userService.deleteToken();
          this.router.navigate(['/login']);
        }
      },
      err => {
        this.serverErrorMessages = err.error.message;
      }
    );
  }

}
