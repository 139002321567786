import { Component, OnInit } from '@angular/core';
import { AdminStripeTransactionService } from '../../shared/adminDashServices/adminStripeTransaction.service';


@Component({
  selector: 'app-home-dashboard-admin',
  templateUrl: './home-dashboard-admin.component.html',
  styleUrls: ['./home-dashboard-admin.component.css']
})
export class HomeDashboardAdminComponent implements OnInit {

  errorServer: Boolean;
  nameOrganization: string;
  totalDonation: number = 0;
  totalTransaction: number = 0;
  averageTransaction: number = 0;
  nbDonor: number = 0;

  totalDonationPerMonth: number = 0;
  totalDonationPerDay: number = 0;

  constructor(private adminStripeTransactionService: AdminStripeTransactionService) { }

  ngOnInit() {
    this.getSumDonationBrut();
    this.getSumDonationMonthBrut();
  }

  getSumFeeE6k() {

  }

  getSumDonationBrut() {
    this.adminStripeTransactionService.sumTransactionStripe().subscribe(
      res => {
        if (res['transactions'].length !== 0) {
          this.totalDonation = res['transactions'][0].total;
          this.totalTransaction = res['transactions'][0].totalTransaction;
          this.averageTransaction = res['transactions'][0].average.toFixed(2);
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  getAverageDonationBrut() {

  }

  getSumDonationMonthBrut() {
    this.adminStripeTransactionService.sumTransactionStripePerMonth().subscribe(
      res => {
        if (res['transactions'].length !== 0) {
          this.totalDonationPerMonth = res['transactions'][0].total;
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  getSumDonationDayBrut() {
    this.adminStripeTransactionService.sumTransactionStripePerDay().subscribe(
      res => {
        if (res['transactions'].length !== 0) {
          this.totalDonationPerDay = res['transactions'][0].total;
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  getNumberOrganization() {

  }

  getNumberTransaction() {

  }
  getNumberDonator() {

  }

  getNumberKiosk() {

  }

}
