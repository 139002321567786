import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { SimpleTimer } from 'ng2-simple-timer';
declare var $ : any;
export interface FormModel {
  captcha?: string;
}
@Component({
  selector: 'app-init-password',
  templateUrl: './init-password.component.html',
  styleUrls: ['./init-password.component.css']
})
export class InitPasswordComponent implements OnInit {
  recaptcha : any[];
  public formModel: FormModel = {};
  model = {
    email: ''
  };
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(private userService: UserService, private router: Router,private st: SimpleTimer) { }
  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  ngOnDestroy(){
   
  }
  ngOnInit() {
    this.st.delTimer('5sec');  
    this.st.newTimer('5sec', 5, true);
    if (this.userService.isLoggedIn())
        this.router.navigateByUrl('/dashboard');
  }

  onSubmit(form: NgForm) {
 var that = this;
 this.model.email = form.value.email;
    this.userService.initPasswordUser(form.value.email).subscribe(
      res => {
        if (res['status'] === true) {

          that.setPageInfo(true);
         // 
        } else {
          //
        }
      },
      err => {

      }
    );

  }
  onGoToLogin(event) {
  this.setPageInfo(false);
    this.router.navigateByUrl('/login')
  }
  setPageInfo(bflag) {
    if (bflag === false)
      $('.bs-example-modal-lg').modal('hide');
    else {
      this.st.delTimer('5sec');
      $('.bs-example-modal-lg').modal('show');
    }


  }

}
