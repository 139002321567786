import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KioskService } from './../../../shared/kiosk.service';
import { Kiosk } from './../../../shared/kiosk.model';
import { KioskServiceBis } from '../../../shared/kiosk.serviceBis';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { UserService } from '../../../shared/user.service';
import { TransactionService } from '../../../shared/transaction.service';
import { OrganisationService } from '../../../shared/organisation.service';
import { formatter } from '../../../shared/formatter';
import { Dashboarddata } from '../../../shared/dashaboardata';
import { KpiService } from '../../../shared/kpi.service';
import { SimpleTimer } from 'ng2-simple-timer';


interface Template {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-read-kiosk',
  templateUrl: './read-kiosk.component.html',
  styleUrls: ['./read-kiosk.component.css']
})


export class ReadKioskComponent implements OnInit {

  formartter: formatter;
  dashboarddata: Dashboarddata;
  timerId: string;
  modelDashboard: any;

  idOrganization: String;
  idKiosk: String;
  displayNameChange: Boolean;
  displayNameInit: String;
  displayNameDefaultText:String;
  kiosk: Kiosk;
  sumTransaction: any = 0;
  totalTransaction: any = 0;
  averageTransaction: any = 0;
  selectedTemplate: String;
  errorServer: Boolean;
  tabVisible: Boolean = false;
  templates: Template[] = [
    { value: '111', viewValue: 'Bleu' },
    { value: '112', viewValue: 'Rouge' },
    { value: '113', viewValue: 'Vert' }
  ];
  constructor(private kioskService: KioskService,
    private route: ActivatedRoute,
    private stripeTransactionService: StripeTransactionService,
    private userService: UserService,
    private kioskServiceBis: KioskServiceBis,
    private kpiService: KpiService,
    private st: SimpleTimer,
    private transactionService: TransactionService,
    private organisationService: OrganisationService) {
    this.kiosk = new Kiosk();
    this.kiosk.address = {
      city: '',
      postaleCode: '',
      state: '',
      line01: '',
      latitude: '',
      longitude: ''
    };
    this.displayNameChange = false;
    this.displayNameInit = "";
    this.formartter = new formatter();
    this.dashboarddata = new Dashboarddata(userService, transactionService, stripeTransactionService, organisationService, kpiService);
    this.modelDashboard = this.dashboarddata.modelDashboard;
  }

  ngOnInit() {
    this.tabVisible = true;
    var that = this;
    this.displayNameDefaultText = "";
    this.userService.getUserProfile().subscribe(
      res => {
        this.idOrganization = res['user'].idOrganisation;
        this.idKiosk = this.route.snapshot.paramMap.get('id');
        setTimeout(function () { that.callback(); }, Dashboarddata.delay);
        this.sumTransactionStripeByKioskOrganisation(res['user'].idOrganisation, this.route.snapshot.paramMap.get('id'));
        this.kioskByIdAndOrganisation(res['user'].idOrganisation, this.route.snapshot.paramMap.get('id'));
        this.getTemplateKiosk();
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  setDisplayDefaultText(){

    if (this.kiosk.displayNameDefault === true){
      this.displayNameDefaultText = "Texte par défaut";
    }else{
      this.displayNameDefaultText = "Texte personalisé";
    }
  }

  updateTemplateKiosk() {

    var data = {
      idTemplate: this.kiosk.idTemplate,
      id: this.kiosk.idTemplate
    }
    var newId = this.kiosk.idTemplate;
    this.kioskService.setTemplateKiosk(this.kiosk.idKiosk, data).subscribe(
      res => {
        this.selectedTemplate = newId;
      },
      err => {
        this.errorServer = true;
        this.kiosk.idTemplate = this.selectedTemplate;
      }
    );

    console.log(this.kiosk.idTemplate);
  }
  getTemplateKiosk() {
    this.kioskService.getTemplateKiosk().subscribe(res => {
      var list = res['template'];
      if (list !== undefined && list !== null) {
        this.templates = [];
        list.forEach(element => {
          this.templates.push({
            value: element.id,
            viewValue: element.nameColor
          })
        });
      }
    },
      err => {
        this.errorServer = true;
      });
  }
  kioskByIdAndOrganisation(idO, idK) {
    this.kioskServiceBis.kioskByIdAndOrganisation(idO, idK).subscribe(
      res => {
        this.kiosk = res['kiosk'][0];
        if (this.kiosk.displayName === undefined || this.kiosk.displayName === null) {
          this.kiosk.displayName = this.kiosk.Organisation.organisationName;
        }
        this.displayNameInit = this.kiosk.displayName;

        if (this.kiosk.displayNameDefault === undefined || this.kiosk.displayNameDefault === null) {
          this.kiosk.displayNameDefault = false;
        }

        this.selectedTemplate = this.kiosk.idTemplate;
        this.setDisplayDefaultText();
        console.log("this.kiosk");
        console.log(this.kiosk);
        // this.kiosk.address = {
        //   city: res['kiosk'][0].city,
        //   postaleCode: res['kiosk'][0].postaleCode,
        //   state: res['kiosk'][0].state,
        //   line01: res['kiosk'][0].line01,
        //   latitude: res['kiosk'][0].latitude,
        //   longitude: res['kiosk'][0].longitude,
        //     };
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  sumTransactionStripeByKioskOrganisation(idO, idK) {
    this.stripeTransactionService.sumTransactionStripeByKioskOrganisation(idO, idK).subscribe(
      res => {
        if (res['transactions'].length !== 0) {
          this.sumTransaction = res['transactions'][0].total;
          this.averageTransaction = res['transactions'][0].average;
          this.totalTransaction = res['transactions'][0].totalTransaction;
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }


  switchKiosk(event) {

    console.log(event);
    if (this.kiosk.displayNameDefault === true) {
      this.kiosk.displayName = this.kiosk.Organisation.organisationName;
      if (this.displayNameInit !== this.kiosk.displayName) {
        this.displayNameChange = true;
      }
    }else{
      this.kiosk.displayName = this.displayNameInit;
      this.changeTitle(this.kiosk.displayName.toString());
    }
    //
    this.setDisplayDefaultText();

  }
  updateDisplayNameKiosk() {

    var data = {
      displayName: this.kiosk.displayName,
      displayNameDefault: this.kiosk.displayNameDefault
    }
    var displayName = this.kiosk.displayName;
    this.kioskService.setDisplayNameKiosk(this.kiosk.idKiosk, data).subscribe(
      res => {
        this.displayNameInit = displayName;
        this.changeTitle(this.kiosk.displayName.toString());
      },
      err => {
        this.errorServer = true;
        this.kiosk.displayName =  this.displayNameInit;
        this.changeTitle(this.kiosk.displayName.toString());
      }
    );

    console.log(this.kiosk.idTemplate);
  }
  changeTitle(searchValue: string) {
    this.displayNameChange = false;
    this.kiosk.displayName = searchValue;
    if (this.displayNameInit !== searchValue) {
      this.displayNameChange = true;
    }

  }

  callback() {
    if (this.userService.isLoggedIn()) {
      this.dashboarddata.formatDataDashboardAll(this);
    } else {
      this.st.delTimer('5sec');
    }
  }


}
