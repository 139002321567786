import { OrganisationService } from './../../shared/organisation.service';
import { Organisation } from './../../shared/organisation.model';
import { UserService } from '../../shared/user.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {

  userDetails = {
    organisation: Organisation
  };
  organisation: Organisation;
  status: Boolean;
  organisationName: String;

  constructor(private organisationService: OrganisationService, private userService: UserService) { }

 ngOnInit() {

  this.userService.getUserProfile().subscribe(
    res => {
      this.userDetails = res['user'];
      this.getOrganisationByUserId(res['user'].idOrganisation);
      // this.userDetails.organisation = res['user'].organisation;
      // console.log(this.userDetails);
    },
    err => {
      console.log(err);
    }
  );

}

getOrganisationByUserId(id) {
  this.organisationService.getOrganisationById(id).subscribe(
    res => {
        console.log(res);
        this.organisation = res['organisation'];
        this.status = true;
    },
    err => {
        this.status = false;
        this.organisationName = err.error.message;
        console.log(err.error.message);
    }
  );
}


}
