import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { User } from '../shared/user.model';
import { Tempdata } from '../shared/tempdata.model';
import { OrganisationService } from '../shared/organisation.service';
import { TempDataService } from '../shared/tempdata.service';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { SimpleTimer } from 'ng2-simple-timer';
import { AngularIbanModule } from 'angular-iban';

var _ = require('lodash');

declare var $: any;
//const URL_R = 'http://localhost:3002/api/organisation/uploadidentiy/r';
//const URL_V = 'http://localhost:3002/api/organisation/uploadidentiy/v';
//const URL_ADD_R = 'http://localhost:3002/api/organisation/uploadidentiy/addr';
//const URL_ADD_V = 'http://localhost:3002/api/organisation/uploadidentiy/addv';

const URL_R = '/api/organisation/uploadidentiy/r';
const URL_V = '/api/organisation/uploadidentiy/v';
const URL_ADD_R = '/api/organisation/uploadidentiy/addr';
const URL_ADD_V = '/api/organisation/uploadidentiy/addv';
interface Template {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-details-account',
  templateUrl: './details-account.component.html',
  styleUrls: ['./details-account.component.css']
})

export class DetailsAccountComponent implements OnInit {
  loading: Boolean = false;
  datato0: number = 0;
  counter: number = 0;
  timerId: string;
  isSameIban: Boolean;

  testIban: '';
  ibanEmpty;

  isIdentityFileValide: Boolean;
  public uploaderRecto: FileUploader = new FileUploader({ url: URL_R, itemAlias: 'identitycard', authToken: "Bearer " + this.userService.getToken() });
  public uploaderVerso: FileUploader = new FileUploader({ url: URL_V, itemAlias: 'identitycard', authToken: "Bearer " + this.userService.getToken() });

  public uploaderAddRecto: FileUploader = new FileUploader({ url: URL_ADD_R, itemAlias: 'identitycard', authToken: "Bearer " + this.userService.getToken() });
  public uploaderAddVerso: FileUploader = new FileUploader({ url: URL_ADD_V, itemAlias: 'identitycard', authToken: "Bearer " + this.userService.getToken() });

  userDetails: User;
  tempdata: Tempdata = new Tempdata();

  file01Uploaded;
  file02Uploaded;
  isIdentityFileValideUploadVerso: Boolean = false;
  isIdentityFileValideUploadRecto: Boolean = false;
  isIdentityFileAddValideUploadVerso: Boolean = false;
  isIdentityFileAddValideUploadRecto: Boolean = false;

  tListTypeOrga: Template[] = [
    { value: '001', viewValue: 'Association (loi 1901)' },
    { value: '002', viewValue: 'Particulier' },
    { value: '003', viewValue: 'Autres' }
  ];

  constructor(private userService: UserService,
    private organisationService: OrganisationService,
    private tempDataService: TempDataService,
    private router: Router,
    private angularIbanModule: AngularIbanModule,
    private st: SimpleTimer) { }




  maxDate = '';
  datetime: Date;
  maxDateYear: number;

  disabled: boolean;
  enabledForm: boolean = true;
  iban: AngularIbanModule;
  idOrganisation: String;
  RNAModel: any = {
    idOrganisation: '',
    organisationName: '',
    address: {
      city: '',
      line1: '',
      postal_code: '',
      state: '',
    },
    description: '',
  };

  accountDetails: any =
    {
      idOrganisation: '',
      organisationName: '',
      business_type: 'individual',
      first_name: '',
      last_name: '',
      phone: '',
      dobindividual: '',
      dob: {
        day: '00',
        month: '00',
        year: '0000',
      },
      address: {
        city: '',
        line1: '',
        postal_code: '',
        state: ''

      },
      rib: '',
      confirmRib: '',
      description: '',
      idcard01: File,
      idCardRecto: false,
      idCardRectoFilename: '',
      idCardVersoFilename: '',
      idCardVerso: false,
      idCardAddRecto: false,
      idCardAddVerso: false,
      idCardAddRectoFilename: '',
      idCardAddVersoFilename: '',
      mcc: '',
      url: '',
      card_payments_statement_descriptor_prefix: '',
      payouts_payments_statement_descriptor_prefix: '',
      payments_statement_descriptor: '',
      capabilities_card_payments_requested: false,
      capabilities_sepa_debit_payments_requested: false,
      capabilities_transfers_requested: false,
      iban: AngularIbanModule,
      ibanConfirm: AngularIbanModule,
      synthese: false,
      titreform: "Veuillez svp renseigner et valider votre profil.",
      state: "edit",
      idCardRectoEditable: true,
      idCardVersoEditable: true,
      idCardRectoChecked: false,
      idCardVersoChecked: false,
      idCardAddRectoEditable: true,
      idCardAddVersoEditable: true,
      button: {
        validate: {
          visible: false
        },
        update: {
          visible: true
        },
        confirm: {
          visible: false
        }
      } // edit,display,sent,wait,approved
      , typeOrga: "",
      typeOrgaText: "",
      RNA: "",
      RNA_visible: false,
      RNAChecked: true
    };
  accountOld: any;
  user: any;
  errorMsg: String;

  refreshStartOne: Boolean = false;

  updateTypeOrga(event) {

  }
 
  manageIHM(data) {
    if (data.button === undefined || data.button === null) {
      var button = {
        validate: {
          visible: false
        },
        update: {
          visible: false
        },
        confirm: {
          visible: false
        }
      };
      data.button = button;
    }
    data.button.validate.visible = false;
    data.button.update.visible = false;
    data.button.confirm.visible = false;
    if (data.synthese === false) {
      data.button.validate.visible = true;
    } else {
      if (data.state !== "wait") {
        data.button.update.visible = true;
        data.button.confirm.visible = true;
      }
    }
  }

  callback() {

    if (this.userService.isLoggedIn()) {
      this.refreshStartOne = true;
      this.getUserData();
    }
  }

  getUserData() {
    var that = this;
    that.setPageInfo(false);
    this.userService.getUserProfile().subscribe(
      res => {

        // control if account is complete

        var resUser = res['user'];
        var resOrg = res['organisation'];

        this.userDetails = resUser;
        if (resUser.accountDetailActive === true) {

        }

        if (resOrg !== undefined && resOrg !== null) {
          this.idOrganisation = resOrg._id;
          if (resOrg.dasboard_open == true) {
            if (resOrg.dasboard_open_count > 0) {
              this.st.delTimer('5sec');
              this.router.navigateByUrl('/dashboard');
              this.loading = false;
            } else {
              that.loading = false;
              that.setPageInfo(true);

            }

          }
        }




        this.tempDataService.getTempDataOrganization(this.userDetails._id).subscribe(
          res => {
            console.log(res);
            if (res !== undefined && res !== null && res.hasOwnProperty("success")) {
              if (res['success'] === true) {
                that.accountDetails = _.cloneDeep(res['data'].objectemp);
              } else {
                that.accountDetails.iban = "";
                that.accountDetails.ibanConfirm = "";
              }
            }


            that.manageIHM(that.accountDetails);
            that.accountOld = _.cloneDeep(that.accountDetails);
            that.loading = false;

            if (that.refreshStartOne === false && that.accountDetails.state === "wait") {

              that.timerId = that.st.subscribe('5sec', () => that.callback());
            }
          }


        )
      },
      err => {
        console.log(err);
      }
    );


  }
  importJOAFE(event) {
    var RNAModel = _.cloneDeep(this.RNAModel);
    this.accountDetails.organisationName = RNAModel.organisationName;
    this.accountDetails.description = RNAModel.description;
    this.accountDetails.address.city = RNAModel.address.city;
    this.accountDetails.address.line1 = RNAModel.address.line1;
    this.accountDetails.address.postal_code = RNAModel.address.postal_code;
    this.accountDetails.address.state = RNAModel.address.state;
    this.updateTempDataForm();
    this.setPageInfoRNA(false);
  }
  mapRNA(infoRna) {

    this.RNAModel = {

      idOrganisation: '',
      organisationName: infoRna.titre,
      address: {
        city: infoRna.adresse_libelle_commune,
        line1: infoRna.adresse_gestion_libelle_voie,
        postal_code: infoRna.adresse_gestion_code_postal,
        state: infoRna.adresse_gestion_pays
      },
      description: infoRna.objet
    };

    return this.RNAModel;

  }

  getRNAInformation(idRna) {
    var that = this;

    this.organisationService.getRNAInformation(idRna).subscribe(
      res => {
        console.log(res);
        that.mapRNA(res['association']);
        that.accountDetails.RNAChecked = true;
        that.setPageInfoRNA(true);

      },
      err => {
        that.accountDetails.RNAChecked = false;
        console.log(err);
      }

    );

  }

  GetValueTypeOrga(event) {

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;
    this.accountDetails.typeOrgaText = selectElementText;
    console.log(selectElementText)
    this.accountDetails.RNA_visible = false;
    this.accountDetails.RNA = "";
    this.accountDetails.RNAChecked = true;
    if (selectedIndex == 1) {
      this.accountDetails.RNA_visible = true;
    } else {

    }

    this.updateTempDataForm();
  }
  onFocusOutRNA(event) {
    this.updateTempDataForm();
    this.getRNAInformation(this.accountDetails.RNA);
  }

  ngOnInit() {
    this.loading = true;
    this.st.delTimer('5sec');
    this.datato0 = 1200;
    this.st.newTimer('5sec', 5, true);

    this.tListTypeOrga = [
      { value: '', viewValue: 'Selectionnez un type' },
      { value: '001', viewValue: 'Association (loi 1901)' },
      { value: '002', viewValue: 'Particulier' },
      { value: '003', viewValue: 'Autres' }
    ];



    this.uploaderVerso.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploaderRecto.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploaderAddRecto.onAfterAddingFile = (file) => { file.withCredentials = false; };
    var that = this;

    // Initialisation Date for date of birth creation account
    const dateTime = new Date();
    this.datetime = new Date();
    const date = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Be careful! January is 0 not 1
    const year = dateTime.getFullYear() - 18;
    this.maxDateYear = dateTime.getFullYear() - 18;
    if (month < 10) {
      this.maxDate = year + '-0' + month + '-' + date;
    } else {
      this.maxDate = year + '-' + month + '-' + date;
    }

    this.getUserData();
  };

  onGoToDashboard(event) {

    this.organizationDashBoardOpened(this.idOrganisation);
  }

  organizationDashBoardOpened(id) {
    var that = this;
    this.organisationService.organizationDashBoardOpened(id).subscribe(
      res => {
        that.router.navigateByUrl('/dashboard');
      },
      err => {

      }
    );
  }
  setPageInfo(bflag) {
    if (bflag === false)
      $('.bs-example-modal-lg').modal('hide');
    else {
      this.st.delTimer('5sec');
      $('.bs-example-modal-lg').modal('show');
    }


  }
  setPageInfoRNA(bflag) {
    if (bflag === false)
      $('#myModal').modal('hide');
    else {
      $('#myModal').modal('show');
    }


  }
  incrementPage() {

  }

  ngAfterViewInit() {
    this.loadExternalScript('assets/js/jquery.js');
    this.loadExternalScript('assets/js/bootstrap.min.js');
    this.loadExternalScript('assets/js/plugins.js');
    this.loadExternalScript('assets/js/functions.js');


  }
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  // If the both IBAN is same
  isSameIbanFunc(confirmRib) {

    if (this.accountDetails.iban === this.accountDetails.ibanConfirm) {
      this.isSameIban = true;
    } else {
      this.isSameIban = false;
    }
  }
  switchKiosk(event) {

    console.log(event);
    var checked = event.currentTarget.checked;

    this.enabledForm = !checked;

  }

  onLogout() {
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }

  fileChangedRecto(event) {
    console.log(event.target.files[0]);

    const idCardRecto = this.uploaderRecto.queue[this.uploaderRecto.queue.length - 1];
    var extension = "";
    if (idCardRecto.file.name.split('.').length > 1) {
      extension = idCardRecto.file.name.split('.').pop();
    }

    idCardRecto.file.name = 'r' + this.userDetails._id;
    if (extension !== "") {
      idCardRecto.file.name = 'r' + this.userDetails._id + "." + extension;
    }

    this.uploaderRecto.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const responseObject = JSON.parse(response);
      this.file02Uploaded = responseObject.success;
      this.isIdentityFileValideUploadRecto = true;
      this.accountDetails.idCardRecto = true;
      this.accountDetails.idCardRectoFilename = responseObject.filename;
      this.updateTempDataForm();
    };
    this.uploaderRecto.uploadItem(idCardRecto);
  }
  fileChangedVerso(event) {
    console.log(event.target.files[0]);
    const idCardVerso = this.uploaderVerso.queue[this.uploaderVerso.queue.length - 1];
    var extension = "";
    if (idCardVerso.file.name.split('.').length > 1) {
      extension = idCardVerso.file.name.split('.').pop();
    }
    idCardVerso.file.name = 'v' + this.userDetails._id;
    if (extension !== "") {
      idCardVerso.file.name = 'v' + this.userDetails._id + "." + extension;
    }

    this.uploaderVerso.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const responseObject = JSON.parse(response);
      this.file01Uploaded = responseObject.success;
      this.isIdentityFileValideUploadVerso = true;
      this.accountDetails.idCardVerso = true;
      this.accountDetails.idCardVersoFilename = responseObject.filename;
      this.updateTempDataForm();

    };

    this.uploaderVerso.uploadItem(idCardVerso);
  }
  deleteFileVerso(event) {
    //this.isIdentityFileValideUploadVerso = false;
    this.onDeleteFileV(event);
  }
  deleteFileRecto(event) {
    //this.isIdentityFileValideUploadRecto = false;
    this.onDeleteFileR(event);
  }



  checkFileSizeUpload(fileUploader,uploaderFile,event) {
     if (fileUploader.file.size > 5120000) {
    // if (fileUploader.file.size > 10000) {
      //alert("La taille du fichier est supérieure à 5 Mo");
      this.modalFileSize(true);
      uploaderFile.queue.slice();
      event.target.value = "";
      // 
      return false;
    }
    return true;
  }
  onCloseFileSize(event){
    this.modalFileSize(false);
  }

  modalFileSize(bflag) {
    if (bflag === false)
      $('.modalFileSize').modal('hide');
    else {     
      $('.modalFileSize').modal('show');
    }


  }

  fileChangedAddRecto(event) {
    console.log(event.target.files[0]);

    const idCardAddRecto = this.uploaderAddRecto.queue[this.uploaderAddRecto.queue.length - 1];
    if (this.checkFileSizeUpload(idCardAddRecto,this.uploaderAddRecto,event)) {
      var extension = "";
      if (idCardAddRecto.file.name.split('.').length > 1) {
        extension = idCardAddRecto.file.name.split('.').pop();
      }

      idCardAddRecto.file.name = 'addr' + this.userDetails._id;
      if (extension !== "") {
        idCardAddRecto.file.name = 'addr' + this.userDetails._id + "." + extension;
      }

      this.uploaderAddRecto.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const responseObject = JSON.parse(response);
        this.file02Uploaded = responseObject.success;
        this.isIdentityFileValideUploadRecto = true;
        this.accountDetails.idCardAddRecto = true;
        this.accountDetails.idCardAddRectoFilename = responseObject.filename;
        this.updateTempDataForm();
      };
      this.uploaderAddRecto.uploadItem(idCardAddRecto);
    }else{
   //  this.uploaderAddRecto.queue.slice();
   //  event.target.value = "";
    
    }
  }
  fileChangedAddVerso(event) {
    console.log(event.target.files[0]);
    const idCardAddVerso = this.uploaderAddVerso.queue[this.uploaderAddVerso.queue.length - 1];
    var extension = "";
    if (idCardAddVerso.file.name.split('.').length > 1) {
      extension = idCardAddVerso.file.name.split('.').pop();
    }
    idCardAddVerso.file.name = 'addv' + this.userDetails._id;
    if (extension !== "") {
      idCardAddVerso.file.name = 'addv' + this.userDetails._id + "." + extension;
    }

    this.uploaderAddVerso.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const responseObject = JSON.parse(response);
      this.file01Uploaded = responseObject.success;
      this.isIdentityFileAddValideUploadVerso = true;
      this.accountDetails.idCardAddVerso = true;
      this.accountDetails.idCardAddVersoFilename = responseObject.filename;
      this.updateTempDataForm();

    };

    this.uploaderAddVerso.uploadItem(idCardAddVerso);
  }
  deleteFileAddVerso(event) {
    //this.isIdentityFileValideUploadVerso = false;
    this.onDeleteFileAddV(event);
  }
  deleteFileAddRecto(event) {
    //this.isIdentityFileValideUploadRecto = false;
    this.onDeleteFileAddR(event);
  }



  onSubmitFile(form: NgForm) {
    const idCardVerso = this.uploaderVerso.queue[this.uploaderVerso.queue.length - 1];
    const idCardRecto = this.uploaderRecto.queue[this.uploaderRecto.queue.length - 1];
    const idCardAddRecto = this.uploaderAddRecto.queue[this.uploaderAddRecto.queue.length - 1];
    // if scan card verso is defined
    if ((idCardVerso === undefined || idCardVerso === null) || (idCardRecto === undefined || idCardRecto === null)) {
      this.isIdentityFileValide = false;
      setTimeout(() => {
        this.isIdentityFileValide = true;
      }, 3000);
    } else {

      idCardVerso.file.name = 'v' + this.userDetails._id;
      idCardRecto.file.name = 'r' + this.userDetails._id;
      idCardRecto.file.name = 'addr' + this.userDetails._id;
      // Upload scan Card Verso
      this.uploaderVerso.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const responseObject = JSON.parse(response);
        this.file01Uploaded = responseObject.success;
        this.isIdentityFileValideUploadVerso = true;
        // console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
      };
      this.uploaderRecto.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const responseObject = JSON.parse(response);
        this.file02Uploaded = responseObject.success;
        this.isIdentityFileValideUploadRecto = true;
        // console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
      };

      this.uploaderAddRecto.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const responseObject = JSON.parse(response);
        this.file02Uploaded = responseObject.success;
        this.isIdentityFileAddValideUploadRecto = true;
        // console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
      };
      this.uploaderRecto.uploadItem(idCardRecto);
      this.uploaderVerso.uploadItem(idCardVerso);
      this.uploaderAddRecto.uploadItem(idCardAddRecto);


    }
  }

  diff(a, b, namespace) {
    namespace = (namespace || '') + '.';

    var keysInA = Object.keys(a),
      keysInB = Object.keys(b);

    var diffA = keysInA.reduce(function (changes, key) {
      var ns = namespace + key;

      if (typeof b[key] == 'undefined') {
        return changes.concat([{ type: 'DELETED', id: ns }]);
      }

      if (a[key] !== b[key]) {
        return changes.concat([{ type: 'CHANGED', id: ns }]);
      }

      if (typeof a[key] === b[key] && typeof a[key] == 'object') {
        return this.diff(a[key], b[key], ns);
      }

      return changes;
    }, []);

    var diffB = keysInB.reduce(function (changes, key) {
      var ns = namespace + key;

      if (typeof a[key] == 'undefined') {
        return changes.concat([{ type: 'ADDED', id: ns }]);
      }

      return changes;
    }, []);

    return diffA.concat(diffB);
  }

  checkUpdateOld() {
    if (this.accountOld !== undefined && this.accountOld !== null) {
      let a = _.cloneDeep(this.accountOld);
      let b = _.cloneDeep(this.accountDetails);
      var listChange = this.diff(a, b, null);
      if (listChange.length > 0) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  updateTempDataForm() {

    if (this.checkUpdateOld() === true) {
      this.tempdata.idModel = "User";
      this.tempdata.id = this.userDetails._id;
      this.tempdata.objectemp = this.accountDetails;
      var that = this;
      this.tempDataService.saveTempDataOrganization(this.tempdata.id, this.tempdata).subscribe(
        res => {
          console.log("success save tempdata");
          this.accountOld = _.cloneDeep(this.accountDetails);
        },
        err => {
          console.log("error save tempdata");
        }
      );
    }

  }

  focusOutFunction(event) {
    this.updateTempDataForm();

  }

  onSubmit_old(form: NgForm) {

    const dobindividual = new Date(form.value.dobindividual);
    const yearIndividual = dobindividual.getFullYear();

    if (yearIndividual > this.maxDateYear) {
      return;
    }

    form.value.dobindividual = dobindividual;
    form.value.user = this.userDetails;
    this.organisationService.postOrganisation(form.value).subscribe(
      res => {
        form.resetForm();
        this.userDetails.accountDetailActive = true;
        this.router.navigateByUrl('/dashboard');
      },
      err => {
        this.errorMsg = 'Une erreur s\'est produite merci de vouloir essayer ulterieuement';
        form.resetForm();
      }
    );
  }
  onSubmit(form: NgForm) {
    this.accountDetails.synthese = true;
    this.manageIHM(this.accountDetails);
    this.updateTempDataForm();

  }


  isIbanEmpty() {
    var that = this;
    if (!this.accountDetails.iban) {
      this.ibanEmpty = true;
      setTimeout(() => {
        // that.ibanEmpty = false;
      }, 2000);

    } else {
      this.accountDetails.synthese = true;
      this.updateTempDataForm();
    }

  }
  onDeleteFileR(event) {
    var that = this;
    this.organisationService.deleteIdentity('r').subscribe(
      res => {
        that.isIdentityFileValideUploadRecto = false;
        that.accountDetails.idCardRecto = false;
        that.updateTempDataForm();
      },
      err => {
        that.isIdentityFileValideUploadRecto = true;
      }
    );
  }

  onDeleteFileV(event) {
    var that = this;
    this.organisationService.deleteIdentity('v').subscribe(
      res => {
        this.isIdentityFileValideUploadVerso = false;
        that.accountDetails.idCardVerso = false;
        that.updateTempDataForm();
      },
      err => {
        this.isIdentityFileValideUploadVerso = true;
      }
    );
  }

  onDeleteFileAddR(event) {
    var that = this;
    this.organisationService.deleteIdentity('addr').subscribe(
      res => {
        that.isIdentityFileAddValideUploadRecto = false;
        that.accountDetails.idCardAddRecto = false;
        that.updateTempDataForm();
      },
      err => {
        that.isIdentityFileAddValideUploadRecto = true;
      }
    );
  }

  onDeleteFileAddV(event) {
    var that = this;
    this.organisationService.deleteIdentity('addv').subscribe(
      res => {
        this.isIdentityFileAddValideUploadVerso = false;
        that.accountDetails.idCardAddVerso = false;
        that.updateTempDataForm();
      },
      err => {
        this.isIdentityFileAddValideUploadVerso = true;
      }
    );
  }


  onBackEdit(event) {

    this.accountDetails.synthese = false;
    this.manageIHM(this.accountDetails);
    this.manageIHM(this.accountOld);
  }

  onValidForm(event) {
    this.isIbanEmpty();

  }

  confirmForm(event) {
    var that = this;
    this.organisationService.postConfirmdata(this.userDetails._id, this.accountDetails).subscribe(
      res => {
        that.accountDetails.state = res['data'].state;
        that.accountDetails.titreform = res['data'].titreform;
        this.manageIHM(that.accountDetails);
        that.updateTempDataForm();
        this.getUserData();
      },
      err => {

      }
    );

  }
}