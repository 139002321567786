import { Component, OnInit } from '@angular/core';
import { SimpleTimer } from 'ng2-simple-timer';

import { Organisation } from '../../shared/organisation.model';
import { StripeTransactionService } from '../../shared/stripeTransaction.service';
import { OrganisationService } from '../../shared/organisation.service';
import { UserService } from '../../shared/user.service';
import { TransactionService } from '../../shared/transaction.service';
import  { formatter } from '../../shared/formatter';
import {Dashboarddata} from '../../shared/dashaboardata';
import { KpiService } from '../../shared/kpi.service';
//import * as CanvasJS from '../../../assets/canvasjs.min';

import { Router } from '@angular/router';
var _ = require('lodash');
import { CountUp } from "countup.js";

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.css']
})
export class HomeDashboardComponent implements OnInit {

  formartter : formatter ; 
  dashboarddata : Dashboarddata;
  timerId: string;
  
  nameOrganization: string;
  
  userDetails = {};
  organisation = {
    externalAccountStripe: false,
  };


  errorServer;
  defExternalAccountStripe;
  defIdentityControl;

  idOrganization: String;

  refreshPage: Boolean = false;

  modelDashboard : any;
 

  constructor(private st: SimpleTimer,
              private userService: UserService,
              private organisationService: OrganisationService,
              private transactionService: TransactionService,
              private stripeTransactionService: StripeTransactionService,
              private kpiService : KpiService,
              private router: Router) {
      this.formartter = new formatter();
      this.dashboarddata = new Dashboarddata(userService,transactionService,stripeTransactionService,organisationService,kpiService);
      this.modelDashboard = this.dashboarddata.modelDashboard;

  }

  ngOnInit() {
    
    this.st.delTimer('5sec');   
    this.st.newTimer('5sec', 5, true);
    
    var that = this;
    this.userService.getUserProfile().subscribe(
      res => {
        if (this.checkDashBoard(res)) {
          this.userDetails = res['user'];
          this.idOrganization = res['user'].idOrganisation;
          setTimeout(function(){ that.callback(); },Dashboarddata.delay);        
          this.timerId = this.st.subscribe('5sec', () => this.callback());
          this.getOrganisationByUserId(res['user'].idOrganisation);      
          this.organizationDashBoardOpened(res['user'].idOrganisation);
        };


      },
      err => {
        this.errorServer = true;
      }
    );
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    this.loadExternalScript('assets/js/jquery.calendario.js');
    this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    this.loadExternalScript('assets/js/functions.js');
  }

  checkDashBoard(oRes) {
    var that = this;
    var resOrg = oRes['organisation'];
    if (resOrg !== undefined && resOrg !== null) {

      if (resOrg.dasboard_open == true) {
        if (resOrg.dasboard_open_count > 0) {
          return true;
        } else {
          this.router.navigateByUrl('/account/details');
          return false;
        }

      } else {
        this.router.navigateByUrl('/account/details');
        return false;
      }
    }

  }

  refresh() {
    this.ngOnInit();
    this.refreshPage = true;
    setTimeout(() => {
      this.refreshPage = null;
    }, 2000);
  }


  organizationDashBoardOpened(id) {

    this.organisationService.organizationDashBoardOpened(id).subscribe(
      res => {

      },
      err => {
        this.errorServer = true;
      }
    );
  }
 
  getOrganisationByUserId(id) {
    this.organisationService.getOrganisationById(id).subscribe(
      res => {
        this.nameOrganization = res['organisation'].organisationName;
        this.defExternalAccountStripe = res['organisation'].externalAccountStripe;
        this.defIdentityControl = res['organisation'].identityControl;
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  
  callback() {
    if (this.userService.isLoggedIn()) {           
      this.dashboarddata.formatDataDashboardAll(this);
    } else {
      this.st.delTimer('5sec');
    }
  }



}
