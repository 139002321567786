import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/user.service';
import { PagerService } from '../../../shared/pager.service';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { KioskService } from '../../../shared/kiosk.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-kiosk',
  templateUrl: './list-kiosk.component.html',
  styleUrls: ['./list-kiosk.component.css']
})
export class ListKioskComponent implements OnInit {

  kioskArray = [];
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  listKiosk: Boolean;
  errorMsg;




  constructor(private stripeTransactionService: StripeTransactionService,
              private userService: UserService,
              private kioskService: KioskService,
              private pagerService: PagerService,
              private router: Router) { }

  ngOnInit() {

    this.userService.getUserProfile().subscribe(
      res => {
        this.getKiosksByOrganisation(res['user'].idOrganisation);
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  searchKiosk(searchValue: string) {
    const kioskArray =  this.kioskArray.filter(x => x._id.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = kioskArray;
    this.setPage(1);
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }


  getKiosksByOrganisation(id) {
    this.kioskService.getKiosksByOrganisation(id).subscribe(
      res => {
        this.allItems = res['kiosks'];
        
        if (this.allItems.length !== 0 ) {
          this.listKiosk = true;
        } else {
          this.listKiosk = false;
        }
        this.setPage(1);

      },
      err => {
        this.errorMsg = true;
      }
    )
  }

}
