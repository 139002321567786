import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/user.service';
import { OrganisationService } from '../../../shared/organisation.service';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { Router } from '@angular/router';
import { StripePayoutService } from '../../../shared/stripePayout.service';
import { PagerService } from '../../../shared/pager.service';
import { TransactionService } from '../../../shared/transaction.service';
import { formatter } from '../../../shared/formatter';
import { Dashboarddata } from '../../../shared/dashaboardata';
import { KpiService } from '../../../shared/kpi.service';

// getStripePayoutByOrganization

@Component({
  selector: 'app-transfer-list',
  templateUrl: './transfer-list.component.html',
  styleUrls: ['./transfer-list.component.css']
})
export class TransferListComponent implements OnInit {


  formartter: formatter;
  dashboarddata: Dashboarddata;
  timerId: string;
  idOrganization: String;
  modelDashboard: any;

  stripePayoutTransaction = [];
  listStripePayoutTransaction: Boolean;
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  errorMsg;

  sumPaid: any = 0;
  sumPending: any = 0;
  sumInTransit: any = 0;
  sumCanceled: any = 0;
  sumFailed: any = 0;


  constructor(private userService: UserService,
    private organisationService: OrganisationService,
    private stripePayoutService: StripePayoutService,
    private transactionService: TransactionService,
    private router: Router,
    private pagerService: PagerService,
    private kpiService: KpiService,
    private stripeTransactionService: StripeTransactionService
    ) { 

      this.formartter = new formatter();
      this.dashboarddata = new Dashboarddata(userService, transactionService, stripeTransactionService, organisationService, kpiService);
      this.modelDashboard = this.dashboarddata.modelDashboard;

    }

  ngOnInit() {
    var that = this;
    this.userService.getUserProfile().subscribe(
      res => {
        console.log(res);
        this.getOrganisationByUserId(res['user'].idOrganisation);
        this.idOrganization = res['user'].idOrganisation;
        setTimeout(function(){ that.callback(); }, Dashboarddata.delay);   
      },
      err => {
        this.errorMsg = true;
      }
    );
  }


  getOrganisationByUserId(id) {
    this.organisationService.getOrganisationById(id).subscribe(
      res => {
        const StripeId = res['organisation'].accountStripe.id;
        this.getPayoutTransaction(StripeId);
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  getPayoutTransaction(id) {
    this.stripePayoutService.getStripePayoutByOrganization(id).subscribe(
      res => {
        this.stripePayoutTransaction = res['stripePayout'];
        this.allItems = res['stripePayout'];
        this.sortPaiement(res['stripePayout']);
        this.stripePayoutTransaction.reverse();

        if (this.allItems.length !== 0) {
          this.listStripePayoutTransaction = true;
        } else {
          this.listStripePayoutTransaction = false;
        }
        this.setPage(1);
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  sortPaiement(stripePayoutTransactions) {
   for (let i = 0; i < stripePayoutTransactions.length; i++) {
     const stripePayoutTransaction = stripePayoutTransactions[i].status;
     switch (stripePayoutTransaction) {
      case 'paid':
        this.sumPaid += Number(stripePayoutTransactions[i].amount);
        break;
      case 'pending':
        this.sumPending += Number(stripePayoutTransactions[i].amount);
        break;
      case 'in_transit':
        this.sumInTransit += Number(stripePayoutTransactions[i].amount);
        break;
      case 'canceled':
        this.sumCanceled += Number(stripePayoutTransactions[i].amount);
        break;
      case 'failed':
        this.sumFailed += Number(stripePayoutTransactions[i].amount);
        break;
    }
   }
  }


  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  callback() {

    this.dashboarddata.formatDataDashboardAll(this);
    //if (this.userService.isLoggedIn()) {
      //this.dashboarddata.formatDataDashboardAll(this);
    //} 
  }

}
