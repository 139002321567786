import { NgForm, FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { KioskService } from './../../../shared/kiosk.service';
import { Organisation } from './../../../shared/organisation.model';
import { OrganisationService } from './../../../shared/organisation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-createkioskadmin',
  templateUrl: './createkioskadmin.component.html',
  styleUrls: ['./createkioskadmin.component.css']
})
export class CreatekioskadminComponent implements OnInit {

  form: FormGroup;
  fb: FormBuilder;
  organisationCurrent: Organisation;
  isOrganisationSelected: Boolean = false;
  organisation = [];
  isSuccessMessage: Boolean = false;

  constructor( private organisationService: OrganisationService, private kioskService: KioskService, fb: FormBuilder,
               private router: Router ) {
    this.form = fb.group({
      id: [''],
      type: ['', Validators.required],
      supervisor: ['', Validators.required],
      line01: ['', Validators.required],
      city: ['', Validators.required],
      postaleCode: ['', Validators.required],
      state: ['', Validators.required],
      location: ['', Validators.required],
      description: ['', Validators.required],
      organisationName: ['']
      });
   }

    ngOnInit() {
    }

    addOrganisation(organisation) {
      this.isOrganisationSelected = true;
      this.organisationCurrent = organisation;
      this.form.controls['organisationName'].setValue(organisation.organisationName);
      // this.organisationCurrent.email = orgnaisation.accountStripe.email;
    }

    deleteSelectedOrganisation() {
      // this.organisationCurrent.organisationName = '';
      // this.organisationCurrent.accountStripe.email = '';
      this.organisationCurrent = null;
      this.isOrganisationSelected = false;
      this.form.controls['organisationName'].setValue('Aucune organisation');
    }

    listOrganisations() {
      this.organisationService.getAllOrganisation().subscribe(
        res => {
          this.organisation = res['organisation'];
        },
        err => {
        }
        );
    }

    onSubmit() {
      /**
       * Define if organisation selected
       */
      this.form.value.isOrganisationSelected = this.isOrganisationSelected;
      /**
       * If organisation selected for kiosk
       */
       if (this.isOrganisationSelected === true) {
          this.form.value.organisation = this.organisationCurrent;
          this.form.value.idOrganisation = this.organisationCurrent._id;
       }

      /**
       * If the form is valid ( all informations valid )
       */
      if (this.form.status === 'VALID') {
              // Create new kioks
              this.kioskService.addKiosk(this.form.value).subscribe(
                res => {
                  console.log(res);
                  this.form.reset();
                  this.isSuccessMessage = true;
                  setTimeout(() => this.isSuccessMessage = false, 4000);
                  this.router.navigateByUrl('dashboard/admin/kiosk');
                },
                err => {

                }
              );
      }
    }

}
