export class User {
    _id: string;
    fullName: string;
    email: string;
    password: string;
    role: [
        {
          name: String,
          description: String
        }
    ];
    organisation: String;
    active: Boolean;
    profil: Boolean;
    valideId: String;
    accountDetailActive: Boolean;
    idOrganisation: String;
}
