import { Component, OnInit } from '@angular/core';
import { Transaction } from '../../../shared/transaction.model';
import { StripeTransaction } from '../../../shared/stripeTransaction.model';
import { TransactionService } from '../../../shared/transaction.service';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { ActivatedRoute } from '@angular/router';
import { Campaign } from './../../../shared/campaign.model';

@Component({
  selector: 'app-read-transaction',
  templateUrl: './read-transaction.component.html',
  styleUrls: ['./read-transaction.component.css']
})
export class ReadTransactionComponent implements OnInit {

  // transaction: Transaction;
  transaction: StripeTransaction;
  campaign: Campaign;
  errorMsg: Boolean;

  constructor(private transactionService: TransactionService, private route: ActivatedRoute,
              private stripeTransactionService: StripeTransactionService) {
    // this.transaction = new Transaction();
    this.transaction = new StripeTransaction();
    this.campaign = new Campaign();
   }

  ngOnInit() {

    this.stripeTransactionService.getTransactionStripeById(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
        this.transaction = res['transaction'][0];
        this.campaign = res['transaction'][0].transacCampaing[0];
      },
      err => {
        this.errorMsg = true;
      });

    // this.transactionService.getTransactionById(this.route.snapshot.paramMap.get('id')).subscribe(
    //   res => {
    //     this.transaction = res['transaction'][0];
    //     this.campaign = res['transaction'][0].transacCampaing[0];
    //   },
    //   err => {
    //     this.errorMsg = true;
    //   });
  }

}
