import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminStripeTransactionService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  sumTransactionStripe() {
    return this.http.get(environment.apiBaseUrl + '/admin/stripe/transaction/sum');
  }

  sumTransactionStripePerDay() {
    return this.http.post(environment.apiBaseUrl + '/admin/stripe/transaction/organization/day/sum/', { date: new Date()});
  }

  sumTransactionStripePerMonth() {
    return this.http.post(environment.apiBaseUrl + '/admin/stripe/transaction/organization/month/sum/', { date: new Date()});
  }

}
