import { Component, OnInit } from '@angular/core';
import { Transaction } from '../../../shared/transaction.model';
import { TransactionService } from '../../../shared/transaction.service';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { CampaignService } from '../../../shared/campaign.service';
import { Campaign } from '../../../shared/campaign.model';
import { UserService } from '../../../shared/user.service';
import { PagerService } from '../../../shared/pager.service';
import { OrganisationService } from '../../../shared/organisation.service';
import { Organisation } from '../../../shared/organisation.model';
import { Router, ActivatedRoute } from '@angular/router';
import * as CanvasJS from '../../../../assets/canvasjs.min';



import { formatter } from '../../../shared/formatter';
import { Dashboarddata } from '../../../shared/dashaboardata';
import { KpiService } from '../../../shared/kpi.service';
import { SimpleTimer } from 'ng2-simple-timer';

@Component({
  selector: 'app-transaction-campaign',
  templateUrl: './transaction-campaign.component.html',
  styleUrls: ['./transaction-campaign.component.css']
})
export class TransactionCampaignComponent implements OnInit {

  formartter: formatter;
  dashboarddata: Dashboarddata;
  timerId: string;
  modelDashboard: any;
  idOrganization: String;
  idCampaign : String;



  transactionArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  listTransaction: Boolean;
  errorMsg;
  validEmail;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  email: String = '';
  isEmailSended;
  isEmailNotSend;
  isEmailSending: Boolean = false;
  chart;
  tabDate = [];
  currentTabDate;
  currentTransaction: Transaction;
  organization: Organisation;
  campaign: Campaign;
  /*
   * Keys values
   */
  totalDonation: any = 0;
  dayDonation: any = 0;
  totalTransaction: any = 0;
  todayDate: Date;
  /*
   * Var for charts
   */
  limitBack: Boolean = false;
  limitNext: Boolean = true;
  pageArray: any;
  indice: number = 1;
  dataPoints = [];
  dataPointsWeek = [];
  dataTransactions = [];
  limitItemsPerChart: any = 10;
  month = [];



  constructor(private transactionService: TransactionService,
    private stripeTransactionService: StripeTransactionService,
    private campaignService: CampaignService,
    private userService: UserService,
    private pagerService: PagerService,
    private organisationService: OrganisationService,
    private router: Router,
    private route: ActivatedRoute,
    private kpiService: KpiService,
    private st: SimpleTimer,
  ) {
    this.campaign = new Campaign();
    this.todayDate = new Date();

    this.formartter = new formatter();
    this.dashboarddata = new Dashboarddata(userService, transactionService, stripeTransactionService, organisationService, kpiService);
    this.modelDashboard = this.dashboarddata.modelDashboard;
  }

  ngOnInit() {

    this.month[0] = 'Janvier';
    this.month[1] = 'Février';
    this.month[2] = 'Mars';
    this.month[3] = 'Avril';
    this.month[4] = 'Mai';
    this.month[5] = 'Juin';
    this.month[6] = 'Juillet';
    this.month[7] = 'Aout';
    this.month[8] = 'Septembre';
    this.month[9] = 'Octobre';
    this.month[10] = 'Novembre';
    this.month[11] = 'Décembre';

    this.makeChartTransactions();
    var that = this;
    this.campaignService.getCampaignByIdCampaign(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
        this.campaign = res['campaign'];
        this.idOrganization = this.campaign.idOrganization;
        this.idCampaign = this.campaign.idCampaign;
        this.makeDataPointsDay();
        setTimeout(function(){ that.callback(); },Dashboarddata.delay);  


      },
      err => {
        this.errorMsg = err;
      }
    );

    this.stripeTransactionService.getTransactionStripeByCampaign(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
        this.totalTransaction = res['transactions'].length;
        this.dataTransactions = res['transactions'];
        this.transactionArray = res['transactions'];
        this.allItems = res['transactions'];
        this.transactionArray.reverse();
        if (this.allItems.length !== 0) {
          this.listTransaction = true;
        } else {
          this.listTransaction = false;
        }
        this.setPage(1);
        this.correlateDataPointsDay();
      },
      err => {
        this.errorMsg = err;
      }
    );

    // this.transactionService.getTransactionsByCampaign(this.route.snapshot.paramMap.get('id')).subscribe(
    //       res => {
    //       this.totalTransaction = res['transactions'].length;
    //       this.dataTransactions = res['transactions'];
    //       this.transactionArray = res['transactions'];
    //       this.allItems =  res['transactions'];
    //       this.transactionArray.reverse();
    //        if (this.allItems.length !== 0 ) {
    //          this.listTransaction = true;
    //        } else {
    //          this.listTransaction = false;
    //        }
    //        this.setPage(1);
    //        this.correlateDataPointsDay();
    //       },
    //       err => {
    //         this.errorMsg = err;
    //       }
    //     );


  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  searchCampaign(searchValue: string) {
    const campaignArray = this.transactionArray.filter(x => x.idStripe.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = campaignArray;
    this.setPage(1);
  }

  selectCurrentCampaign(transaction) {
    this.currentTransaction = transaction;
  }

  selectTransaction(transaction) {
    this.isEmailSending = false;
    this.isEmailNotSend = null;
    this.isEmailSended = null;
    this.validEmail = null;
    this.email = transaction['email'];
    this.currentTransaction = transaction;
    this.currentTransaction['organizationName'] = this.organization.organisationName;
  }

  selectTypeChart(typeChart: any) {
    if (typeChart === 'day') {
      this.chart = new CanvasJS.Chart('chartContainer', {
        animationEnabled: true,
        exportEnabled: true,
        theme: 'light1', //"light1", "dark1", "dark2"
        title: {
          // text: 'Projet plantation arbres'
        },
        data: [{
          type: "splineArea",
          indexLabel: "{y}€",
          showInLegend: false,
          toolTipContent: " <b>{label}</b><br>Montant: {y}€<br>Transaction: {nbTransactions}",
          dataPoints: null
        }]
      });
      this.initChartDay();
    } else if (typeChart === 'week') {
      this.chart = new CanvasJS.Chart('chartContainer', {
        animationEnabled: true,
        exportEnabled: true,
        theme: 'light1', //"light1", "dark1", "dark2"
        title: {
          // text: 'Projet plantation arbres'
        },
        data: [{
          type: "column",
          indexLabel: "{y}€",
          showInLegend: false,
          toolTipContent: " <b>{label}</b><br>Montant: {y}€<br>Transaction: {nbTransactions}",
          dataPoints: null
        }]
      });
      this.makeDataPointsWeek();
    } else if (typeChart === 'month') {
      this.chart = new CanvasJS.Chart('chartContainer', {
        animationEnabled: true,
        exportEnabled: true,
        theme: 'light1', //"light1", "dark1", "dark2"
        title: {
          // text: 'Projet plantation arbres'
        },
        data: [{
          type: 'column',
          indexLabel: '{y}€',
          showInLegend: false,
          toolTipContent: '<b>{label}</b><br>Montant: {y}€<br>Transaction: {nbTransactions}',
          dataPoints: null
        }]
      });
      this.initChartMonth();
    }
  }

  initChartDay() {

    this.makeDataPointsDay();
    this.correlateDataPointsDay();
  }

  initChartMonth() {

    this.makeDataPointsMonth();
    this.correlateDataPointsMonth();
  }

  makeDataPointsDay() {

    this.dataPoints = [];
    this.indice = 1;
    /*
     * Date when campaign start
     */
    let startDateCampaign = new Date(this.campaign.created);

    /*
     * Date system today
     */
    let todayDate = new Date();
    /*
     * Format date "dd/mm/aaaa"
     */
    let stringStartDate = this.formatDate(startDateCampaign);
    let stringTodayDate = this.formatDate(todayDate);
    let stringCurrentDate = this.formatDate(startDateCampaign);
    let tempTab = [];
    let i = 0;

    if (stringCurrentDate === stringTodayDate) {
      this.dataPoints.push({
        x: i,
        y: 0,
        label: stringCurrentDate,
        nbTransactions: 0
      });
    }

    /*
     * While current date is not equals to today date
     */
    while (stringCurrentDate !== stringTodayDate) {

      stringCurrentDate = this.formatDate(startDateCampaign);
      this.dataPoints.push({
        x: i,
        y: 0,
        label: stringCurrentDate,
        nbTransactions: 0
      });
      const currrentDate = startDateCampaign.getDate() + 1;
      startDateCampaign.setDate(currrentDate);
      i++;
    }

  }

  correlateDataPointsDay() {
    this.dayDonation = 0;
    this.totalDonation = 0;
    const stringTodayDate = this.formatDate(this.todayDate);
    for (let i = 0; i < this.dataTransactions.length; i++) {
      const objectDateTransaction = new Date(this.dataTransactions[i].date);

      const dateTransaction = this.formatDate(objectDateTransaction);
      const result = this.dataPoints.filter(data => (data.label === dateTransaction));
      /*
       * Sum today donation
       */
      if (stringTodayDate === dateTransaction) {
        this.dayDonation += Number(this.dataTransactions[i].amount);
      }

      if (result.length !== 0) {
        for (let j = 0; j < this.dataPoints.length; j++) {
          if (this.dataPoints[j].label === dateTransaction) {
            this.totalDonation += Number(this.dataTransactions[i].amount);
            this.dataPoints[j].y += Number(this.dataTransactions[i].amount);
            this.dataPoints[j].nbTransactions += 1;
          }
        }
      }
    }
    this.initChart();
  }

  makeDataPointsWeek() {
    this.dataPoints = [];
    this.indice = 1;
    /*
     * Date when campaign start
     */
    const startDateCampaign = new Date(this.campaign.created);
    const todayDate = new Date();
    const weekObject = this.getWeekNumber(startDateCampaign);
    const weekTodayObject = this.getWeekNumber(todayDate);
    console.log(weekObject);

    console.log(weekTodayObject);

    // let currentDate = weekObject[0] + '/S' + weekObject[1];

    // if(weekObject[1] === 56) {
    //   let year = weekObject[0] + 1;
    //   let week = 1

    // }

    // for (let i: any = weekObject[1]; i <= weekTodayObject[1]; i++ )  {
    // this.dataPoints.push({
    //   x: i,
    //   y: 0,
    //   label: weekObject[0] + ' - S' + i,
    //   nbTransactions: 0
    // });
    // }
    // this.correlateDataPointsWeek();
  }

  correlateDataPointsWeek() {
    const stringTodayDate = this.formatDate(this.todayDate);

    for (let i = 0; i < this.dataTransactions.length; i++) {
      const objectDateTransaction = new Date(this.dataTransactions[i].created);
      const weekObjectTransction = this.getWeekNumber(objectDateTransaction);

      const result = this.dataPoints.filter(data => (data.x === weekObjectTransction[1]));

      if (result.length !== 0) {
        for (let j = 0; j < this.dataPoints.length; j++) {
          if (this.dataPoints[j].x === weekObjectTransction[1]) {
            this.dataPoints[j].y += Number(this.dataTransactions[i].ammount);
            this.dataPoints[j].nbTransactions += 1;
          }
        }
      }
    }
    this.initChart();
  }

  makeDataPointsMonth() {
    this.dataPoints = [];
    this.indice = 1;
    /*
     * Date when campaign start
     */
    const startDateCampaign = new Date(this.campaign.created);
    let stringCurrentDate = startDateCampaign.getMonth() + '/' + startDateCampaign.getFullYear();
    /*
     * Date system today
     */
    const todayDate = new Date();
    const stringTodaytDate = todayDate.getMonth() + '/' + todayDate.getFullYear();


    let i = 0;

    if (stringCurrentDate === stringTodaytDate) {
      this.dataPoints.push({
        x: i,
        y: 0,
        label: this.month[startDateCampaign.getMonth()] + ' ' + startDateCampaign.getFullYear(),
        nbTransactions: 0
      });
    }

    /*
     * While current date is not equals to today date
     */
    while (stringCurrentDate !== stringTodaytDate) {

      stringCurrentDate = startDateCampaign.getMonth() + '/' + startDateCampaign.getFullYear();

      this.dataPoints.push({
        x: i,
        y: 0,
        label: this.month[startDateCampaign.getMonth()] + ' ' + startDateCampaign.getFullYear(),
        nbTransactions: 0
      });

      const currentDate = startDateCampaign.getMonth() + 1;
      startDateCampaign.setMonth(currentDate);
      i++;
    }

  }

  correlateDataPointsMonth() {


    for (let i = 0; i < this.dataTransactions.length; i++) {
      // const objectDateTransaction = new Date(this.dataTransactions[i].created);
      // const monthObjectTransaction = objectDateTransaction.getMonth();

      const objectDateTransaction = new Date(this.dataTransactions[i].date);
      const monthObjectTransaction = this.month[objectDateTransaction.getMonth()] + ' ' + objectDateTransaction.getFullYear();


      const result = this.dataPoints.filter(data => (data.label === monthObjectTransaction));

      if (result.length !== 0) {
        for (let j = 0; j < this.dataPoints.length; j++) {
          if (this.dataPoints[j].label === monthObjectTransaction) {
            this.dataPoints[j].y += Number(this.dataTransactions[i].amount);
            this.dataPoints[j].nbTransactions += 1;
          }
        }
      }
    }

    this.initChart();

  }

  // makeDataPointsMonthOld() {

  //   this.dataPoints = [];
  //   this.indice = 1;
  //   /*
  //    * Date when campaign start
  //    */
  //   const startDateCampaign = new Date(this.campaign.created);

  //   const monthStartCampaign = startDateCampaign.getMonth();

  //   let month = [];

  //   month[0] = 'Janvier';
  //   month[1] = 'Février';
  //   month[2] = 'Mars';
  //   month[3] = 'Avril';
  //   month[4] = 'Mai';
  //   month[5] = 'Juin';
  //   month[6] = 'Juillet';
  //   month[7] = 'Aout';
  //   month[8] = 'Septembre';
  //   month[9] = 'Octobre';
  //   month[10] = 'Novembre';
  //   month[11] = 'Décembre';

  //   for (let i:any = monthStartCampaign; i <= 11; i++ )  {
  //   this.dataPoints.push({
  //     x: i,
  //     y: 0,
  //     label: month[i],
  //     nbTransactions: 0
  //   });
  //   }
  // }

  // correlateDataPointsMonthOld() {

  // const stringTodayDate = this.formatDate(this.todayDate);

  // for (let i = 0; i < this.dataTransactions.length; i++) {
  //   const objectDateTransaction = new Date(this.dataTransactions[i].created);
  //   const monthObjectTransaction = objectDateTransaction.getMonth();

  //   const result = this.dataPoints.filter(data => (data.x === monthObjectTransaction));

  //   if (result.length !== 0) {
  //     for (let j = 0 ; j < this.dataPoints.length ; j++) {
  //       if (this.dataPoints[j].x === monthObjectTransaction) {
  //           this.dataPoints[j].y += this.dataTransactions[i].ammount;
  //           this.dataPoints[j].nbTransactions += 1;
  //       }
  //     }
  //   }
  // }

  // this.initChart();

  // }

  initChart() {
    this.limitBack = false;
    this.limitNext = true;

    if (this.limitItemsPerChart < this.dataPoints.length) {

      this.limitNext = true;
      let currentDataPoints = [];

      let value02 = (this.dataPoints.length - this.limitItemsPerChart);
      value02 = value02 + this.limitItemsPerChart;
      const sizeArray = this.dataPoints.length;
      currentDataPoints = this.dataPoints.slice(sizeArray - this.limitItemsPerChart, sizeArray);
      this.updateChart(currentDataPoints);

    } else {
      this.limitBack = true;
      this.limitNext = true;
      this.updateChart(this.dataPoints);
    }

  }

  paginationArrayPoint() {
    this.limitNext = false;
    let currentDataPoints = [];
    this.indice = this.indice + 1;
    let value01 = this.indice * this.limitItemsPerChart;
    let value02 = this.dataPoints.length - value01;
    value02 = value02 + this.limitItemsPerChart;
    if (value01 < this.dataPoints.length) {
      currentDataPoints = this.dataPoints.slice(this.dataPoints.length - value01, value02);
      this.updateChart(currentDataPoints);
    } else {
      this.limitBack = true;
      value01 = this.indice * this.limitItemsPerChart;
      value02 = this.dataPoints.length - value01;
      value02 = value02 + this.limitItemsPerChart;
      currentDataPoints = this.dataPoints.slice(0, value02);
      this.updateChart(currentDataPoints);
    }
  }

  paginationNextArrayPoint() {
    this.limitBack = false;
    let currentDataPoints = [];

    let value01 = this.indice * this.limitItemsPerChart;
    let value02 = this.dataPoints.length - value01;
    value02 = value02 + this.limitItemsPerChart;
    let value03 = value02 + this.limitItemsPerChart;

    if (value03 < this.dataPoints.length) {
      currentDataPoints = this.dataPoints.slice(value02, value03);
      this.indice = this.indice - 1;
      this.updateChart(currentDataPoints);
    } else {
      this.limitNext = true;
      value01 = this.indice * this.limitItemsPerChart;
      value02 = this.dataPoints.length - value01;
      value02 = value02 + this.limitItemsPerChart;
      currentDataPoints = this.dataPoints.slice(value02, this.dataPoints.length);
      this.indice = 1;
      this.updateChart(currentDataPoints);
    }
  }

  makeChartTransactions() {

    this.chart = new CanvasJS.Chart('chartContainer', {
      animationEnabled: true,
      exportEnabled: true,
      theme: 'light1', //"light1", "dark1", "dark2"
      title: {
        // text: 'Projet plantation arbres'
      },
      data: [{
        type: 'splineArea',
        indexLabel: "{y}€",
        color: "#1abc9c",
        showInLegend: false,
        toolTipContent: " <b>{label}</b><br>Montant: {y}€<br>Transaction: {nbTransactions}",
        dataPoints: null
      }]
    });

    this.chart.render();
    // this.paginationArrayPoint();
  }

  updateChart(dataPoints) {
    this.chart.creditText = '';
    this.chart.creditHref = '';
    this.chart.options.data[0].dataPoints = dataPoints;
    this.chart.render();
  }

  // formatWeekNumber(weekObject)
  formatDate(date) {
    const month = date.getMonth() + 1;
    return date.getDate() + '/' + month + '/' + date.getFullYear();
  }

  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
    // return d.getUTCFullYear() + '/S' + weekNo;
  }

  callback() {
    if (this.userService.isLoggedIn()) {           
      this.dashboarddata.formatDataDashboardAll(this);
    } else {
      this.st.delTimer('5sec');
    }
  }

}
