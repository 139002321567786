// built-in
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// components
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { InitPasswordComponent } from './user/init-password/init-password.component';
//routes
import { appRoutes } from './routes';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { UserService } from './shared/user.service';
import { OrganisationService } from './shared/organisation.service';


//other
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardmenuComponent } from './dashboard/dashboardmenu/dashboardmenu.component';
import { ComfirmAccountComponent } from './comfirm-account/comfirm-account.component';
import { DetailsAccountComponent } from './details-account/details-account.component';
import { ReactiveFormsModule } from '@angular/forms';
// import { FileSelectDirective } from 'ng2-file-upload';

//import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadModule } from 'ng2-file-upload';
import { AngularIbanModule } from 'angular-iban';
import { TransactionsComponent } from './dashboard/transactions/transactions.component';
import { HomeDashboardComponent } from './dashboard/home-dashboard/home-dashboard.component';
import { HomeDashboardMonthComponent } from './dashboard/home-dashboard_month/home-dashboard_month.component';
import { KioskComponent } from './dashboard/kiosk/kiosk.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { OrganizationComponent } from './dashboard/organization/organization.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { DashboardMenuAdminComponent } from './dashboard-admin/dashboard-menu-admin/dashboard-menu-admin.component';
import { from } from 'rxjs';
import { HomeDashboardAdminComponent } from './dashboard-admin/home-dashboard-admin/home-dashboard-admin.component';
import { KioskdAdminComponent } from './dashboard-admin/kioskd-admin/kioskd-admin.component';
import { CreatekioskadminComponent } from './dashboard-admin/kioskd-admin/createkioskadmin/createkioskadmin.component';
import { ListkioskadminComponent } from './dashboard-admin/kioskd-admin/listkioskadmin/listkioskadmin.component';
import { UpdatekioskadminComponent } from './dashboard-admin/kioskd-admin/updatekioskadmin/updatekioskadmin.component';
import { OrganisationAdminComponent } from './dashboard-admin/organisation-admin/organisation-admin.component';
import { CreateOrganisationAdminComponent } from './dashboard-admin/organisation-admin/create-organisation-admin/create-organisation-admin.component';
import { UpdateOrganisationAdminComponent } from './dashboard-admin/organisation-admin/update-organisation-admin/update-organisation-admin.component';
import { ListOrganisationAdminComponent } from './dashboard-admin/organisation-admin/list-organisation-admin/list-organisation-admin.component';
import { ReadOrganisationAdminComponent } from './dashboard-admin/organisation-admin/read-organisation-admin/read-organisation-admin.component';
import { ListKioskOrganisationAdminComponent } from './dashboard-admin/organisation-admin/list-kiosk-organisation-admin/list-kiosk-organisation-admin.component';
import { DonationCampaignAdminComponent } from './dashboard-admin/donation-campaign-admin/donation-campaign-admin.component';
import { ListDonationCampaignAdminComponent } from './dashboard-admin/donation-campaign-admin/list-donation-campaign-admin/list-donation-campaign-admin.component';
import { DonationCardComponent } from './donation-card/donation-card.component';
import { ExternalAccountStripeComponent } from './dashboard/external-account-stripe/external-account-stripe.component';
import { UploadIdentityComponent } from './dashboard/upload-identity/upload-identity.component';
import { CampaignComponent } from './dashboard/campaign/campaign.component';
import { ListcampaignComponent } from './dashboard/campaign/listcampaign/listcampaign.component';
import { CreatecampaignComponent } from './dashboard/campaign/createcampaign/createcampaign.component';
import { UpdatecampaignComponent } from './dashboard/campaign/updatecampaign/updatecampaign.component';
import { ReadcampaignComponent } from './dashboard/campaign/readcampaign/readcampaign.component';
import { ListTransactionComponent } from './dashboard/transactions/list-transaction/list-transaction.component';
import { ReadTransactionComponent } from './dashboard/transactions/read-transaction/read-transaction.component';
import { TransactionCampaignComponent } from './dashboard/campaign//transaction-campaign/transaction-campaign.component';
import { ChartDashboardComponent } from './dashboard/chart-dashboard/chart-dashboard.component';
import { DonatorComponent } from './dashboard/donator/donator.component';
import { DonatorListComponent } from './dashboard/donator/donator-list/donator-list.component';
import { FiscalReceiptDonatorComponent } from './dashboard/donator/fiscal-receipt-donator/fiscal-receipt-donator.component';
import { TransferComponent } from './dashboard/transfer/transfer.component';
import { TransferListComponent } from './dashboard/transfer/transfer-list/transfer-list.component';
import { TransactionDonatorComponent } from './dashboard/donator/transaction-donator/transaction-donator.component';
import { ListKioskComponent } from './dashboard/kiosk/list-kiosk/list-kiosk.component';
import { ReadKioskComponent } from './dashboard/kiosk/read-kiosk/read-kiosk.component';
import { TransactionKioskComponent } from './dashboard/kiosk/transaction-kiosk/transaction-kiosk.component';
import { TemplateKioskComponent } from './dashboard/kiosk/template-kiosk/template-kiosk.component';

import { UpdatePasswordUserComponent } from './user/update-password-user/update-password-user.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyFormat, CurrencyFormatDecimal, CurrencyFormatWithoutDecimal, NumberFormatDecimalFr, NumberFormatFr, CurrencyFormatWithoutDecimalCompress } from './shared/pipe.component';
import { MatRadioModule } from '@angular/material/radio';



import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';



import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaFormsModule,RecaptchaModule } from 'ng-recaptcha';
import { HomeDashboardYearComponent } from './dashboard/home-dashboard-year/home-dashboard-year.component';
import { HomeDashboardTodayComponent } from './dashboard/home-dashboard-today/home-dashboard-today.component';
@NgModule({
  declarations: [
    AppComponent,
    // FileSelectDirective,
    UserComponent,
    SignUpComponent,
    UserProfileComponent,
    SignInComponent,
    DashboardComponent,
    DashboardmenuComponent,
    ComfirmAccountComponent,
    DetailsAccountComponent,
    TransactionsComponent,
    HomeDashboardComponent,
    HomeDashboardMonthComponent,
    KioskComponent,
    ProfileComponent,
    OrganizationComponent,
    DashboardAdminComponent,
    DashboardMenuAdminComponent,
    HomeDashboardAdminComponent,
    KioskdAdminComponent,
    CreatekioskadminComponent,
    ListkioskadminComponent,
    UpdatekioskadminComponent,
    OrganisationAdminComponent,
    CreateOrganisationAdminComponent,
    UpdateOrganisationAdminComponent,
    ListOrganisationAdminComponent,
    ReadOrganisationAdminComponent,
    ListKioskOrganisationAdminComponent,
    DonationCampaignAdminComponent,
    ListDonationCampaignAdminComponent,
    InitPasswordComponent,
    ExternalAccountStripeComponent,
    UploadIdentityComponent,
    CampaignComponent,
    ListcampaignComponent,
    CreatecampaignComponent,
    UpdatecampaignComponent,
    ReadcampaignComponent,
    ListTransactionComponent,
    ReadTransactionComponent,
    TransactionCampaignComponent,
    ChartDashboardComponent,
    DonatorComponent,
    TransferComponent,
    TransferListComponent,
    DonatorListComponent,
    TransactionDonatorComponent,
    ListKioskComponent,
    ReadKioskComponent,
    TransactionKioskComponent,
    TemplateKioskComponent,
    UpdatePasswordUserComponent,
    CurrencyFormat,
    CurrencyFormatDecimal,
    CurrencyFormatWithoutDecimal,
    NumberFormatDecimalFr,
    CurrencyFormatWithoutDecimalCompress,
    NumberFormatFr,
    DonationCardComponent,
    HomeDashboardYearComponent,
    HomeDashboardTodayComponent,
    FiscalReceiptDonatorComponent




  ],
  imports: [
    BrowserModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    AngularIbanModule,
    // FileSelectDirective
    FileUploadModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatBadgeModule,
    MatBottomSheetModule,   
    MatButtonToggleModule,  
    MatCheckboxModule,
    MatDialogModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
   MatSelectModule,
    MatTableModule,
    MatTabsModule,
    NgbModule 
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },  
  AuthGuard, UserService, OrganisationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
