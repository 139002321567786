import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KioskServiceBis } from '../../../shared/kiosk.serviceBis';
import { Kiosk } from '../../../shared/kiosk.model';

import { TransactionService } from '../../../shared/transaction.service';
import { OrganisationService } from '../../../shared/organisation.service';
import { Organisation } from '../../../shared/organisation.model';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { UserService } from '../../../shared/user.service';
import { PagerService } from '../../../shared/pager.service';
import * as CanvasJS from '../../../../assets/canvasjs.min';

import  { formatter } from '../../../shared/formatter';
import {Dashboarddata} from '../../../shared/dashaboardata';
import { KpiService } from '../../../shared/kpi.service';
import { SimpleTimer } from 'ng2-simple-timer';
@Component({
  selector: 'app-transaction-kiosk',
  templateUrl: './transaction-kiosk.component.html',
  styleUrls: ['./transaction-kiosk.component.css']
})
export class TransactionKioskComponent implements OnInit {

  formartter : formatter ; 
  dashboarddata : Dashboarddata;
  timerId: string;
  modelDashboard : any;

  idOrganization: String;
  idKiosk: String;
  kiosk: Kiosk;
  organisation: Organisation;
  errorServer: Boolean = false;
  totalTransaction: any = 0;
  sumTransaction: any = 0;
  averageTransaction: any = 0;

   /**
   * Data pie chart
   */
  chartPie: any;
  dataPointChartDonut = [];
  /**
   * Data bar chart
   */
  limitBackBarChart: Boolean = false;
  limitNextBarChart: Boolean = true;
  chartBar: any;
  nbMonthChartBar: any = 5;
  dataPointChartBar = [];
  indiceBarChart: any = 0;
  /**
   * Data line chart
   */
  chartLine: any;
  limitBackLineChart: Boolean = false;
  limitNextLineChart: Boolean = true;
  nbDaysChartLine: Number = 10;
  indiceLineChart: any = 0;
  dataLineChart: any;
  currentDataChart: any;

  constructor(
    private st: SimpleTimer,
    private route: ActivatedRoute,
    private stripeTransactionService: StripeTransactionService,
    private userService: UserService,
    private pagerService: PagerService,
    private kioskServiceBis: KioskServiceBis,
    private kpiService : KpiService,
    private transactionService : TransactionService,
    private organisationService : OrganisationService
    ) {
      this.kiosk = new Kiosk();
      this.organisation = new Organisation();

      this.formartter = new formatter();
      this.dashboarddata = new Dashboarddata(userService,transactionService,stripeTransactionService,organisationService,kpiService);
      this.modelDashboard = this.dashboarddata.modelDashboard;
    }

  ngOnInit() {
    var that = this;
    this.userService.getUserProfile().subscribe(
      res => {
        this.organisation._id = res['user'].idOrganisation;
        this.idOrganization = res['user'].idOrganisation;
        this.idKiosk = this.route.snapshot.paramMap.get('id');
        setTimeout(function(){ that.callback(); },Dashboarddata.delay);  
        this.timerId = this.st.subscribe('5sec', () => this.callback());  

        this.sumTransactionStripeByKioskOrganisation(res['user'].idOrganisation, this.route.snapshot.paramMap.get('id'));
        this.getSumCampaignTransactionsByKiosk(res['user'].idOrganisation, this.route.snapshot.paramMap.get('id'));
        this.kioskByIdAndOrganisation(res['user'].idOrganisation, this.route.snapshot.paramMap.get('id'));
        const startDate = new Date();
        this.updateChartLine(startDate, this.nbDaysChartLine, this.organisation._id,  this.route.snapshot.paramMap.get('id'));
        this.updateChartBar(startDate, this.nbMonthChartBar, this.organisation._id, this.route.snapshot.paramMap.get('id'));
      },

      err => {
        this.errorServer = true;
      }
    );
  }

  kioskByIdAndOrganisation(idO, idK) {
    this.kioskServiceBis.kioskByIdAndOrganisation(idO, idK).subscribe(
      res => {
        this.kiosk = res['kiosk'][0];
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  // getKioksById(id) {
  //   this.kioskServiceBis.getKioksById(id).subscribe(
  //     res => {
  //       console.log(res);
  //     },
  //     err => {

  //     }
  //   );
  // }

  sumTransactionStripeByKioskOrganisation(idO, idK) {
    this.stripeTransactionService.sumTransactionStripeByKioskOrganisation(idO, idK).subscribe(
      res => {
        if (res['transactions'].length !== 0) {
            this.sumTransaction = res['transactions'][0].total;
            this.averageTransaction = res['transactions'][0].average;
            this.totalTransaction = res['transactions'][0].totalTransaction;
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }


  getSumCampaignTransactionsByKiosk(idO, idK) {
    this.stripeTransactionService.getSumCampaignTransactionsByKiosk(idO, idK).subscribe(
      res => {
        this.updateChartPie(res['campaign']);
      },
      err => {
        this.errorServer = true;
      }
    );
  }

    /**
   * Chart pie
   * @param campaigns
   */
  updateChartPie(campaigns) {
    this.dataPointChartDonut = [];
    for (let i = 0; i < campaigns.length; i++) {
      this.dataPointChartDonut.push({
        y: campaigns[i].sumBrut,
        name: campaigns[i].title
      });
    }
    this.chartPie = new CanvasJS.Chart('chartContainerPie', {
      // theme: "dark1",
      exportFileName: 'Doughnut Chart',
      exportEnabled: true,
      animationEnabled: true,
      title: {
        text: 'Répartition totale par borne'
      },
      legend: {
        cursor: 'pointer',
        itemclick: explodePie
      },
      data: [{
        type: 'pie',
        innerRadius: 90,
        // showInLegend: true,
        toolTipContent: '<b>{name}</b>: {y}€ (#percent%)',
        indexLabel: '{name} - #percent%',
        dataPoints: this.dataPointChartDonut
      }]
    });

    this.chartPie.render();

    function explodePie(e) {
      if (typeof (e.dataSeries.dataPoints[e.dataPointIndex].exploded) === 'undefined' || !e.dataSeries.dataPoints[e.dataPointIndex].exploded) {
        e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
      } else {
        e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
      }
      e.chart.render();
    }
  }

  /**
 * CHART BAR
 */
updateChartBar(startDate, nbMonth, idO, idK) {
  this.dataPointChartBar = [];
    this.stripeTransactionService.getSumCampaignTransactionsPerMonthByKiosk(startDate, nbMonth, idO, idK).subscribe(
      res => {
        this.dataPointChartBar = res['transactions'];
        console.log(this.dataPointChartBar);
        this.chartBar = new CanvasJS.Chart('chartContainerBar', {
          animationEnabled: true,
          exportEnabled: true,
          // theme: "dark2", // "light1", "light2", "dark1", "dark2"
          title: {
            text: 'Revenu mensuel par borne'
          },
          axisY: {
            title: 'EUROS ( € )'
          },
          data: [{
            type: 'column',
            showInLegend: false,
            legendMarkerColor: 'grey',
            yValueFormatString: '#0.## €',
            indexLabel: '{y}',
            dataPoints : this.dataPointChartBar
          }]
        });
        this.chartBar.render();
      },
      err => {
        this.errorServer = true;
      });
  }

  pushBackBarChart() {
    this.indiceBarChart++;
    this.limitNextBarChart = false;
    const startDate = new Date();
    const nbMonth = this.indiceBarChart * this.nbMonthChartBar;
    const subStartDate = this.substractMonth(startDate, nbMonth);
    const idO = this.organisation._id;
    const idK = this.kiosk.idKiosk;
    this.updateChartBar(subStartDate,  this.nbMonthChartBar, idO, idK);
  }

  pushNextBarChart() {
    this.indiceBarChart--;
    if (this.indiceBarChart !== 0) {
      const startDate = new Date();
      const nbMonth = this.indiceBarChart * this.nbMonthChartBar;
      const subStartDate = this.substractMonth(startDate, nbMonth);
      this.updateChartBar(subStartDate, this.nbMonthChartBar, this.organisation._id, this.kiosk.idKiosk);
    } else {
      this.limitNextBarChart = true;
      const startDate = new Date();
      const nbMonth = this.indiceBarChart * this.nbMonthChartBar;
      this.updateChartBar(startDate, this.nbMonthChartBar, this.organisation._id, this.kiosk.idKiosk);
    }
  }

  substractMonth(date, days) {
    const copy = new Date(Number(date));
    copy.setMonth(date.getMonth() - days);
    return copy;
  }

  /**
 * Chart line
 * @param starDate
 * @param nbDays
 * @param idO
 * @param idK
 */
updateChartLine(starDate, nbDays, idO, idK) {
  const that = this;
  this.stripeTransactionService.getSumTransactionsByKioskCampaignPerDay(starDate, nbDays, idO, idK).subscribe(
    res => {
      this.controlNextButton();
      this.controlBackButton(res['campaign']);
      this.dataLineChart = res['campaign'];
      this.chartLine = new CanvasJS.Chart('chartContainer02', {
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: 'Collecte jour par campagne'
        },
        axisY: {
          title: 'EURO ( € )'
        },
        toolTip: {
          shared: true
        },
        legend: {
          cursor: 'pointer',
          itemclick: toggleDataSeries
        },
        data: this.currentDataChart
      });
      this.chartLine.render();
      function toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === 'undefined' || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        that.chartLine.render();
      }
    },
    err => {
      this.errorServer = true;
    }
  );
}

substracDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() - days);
  return copy;
}

pushBack() {
  this.indiceLineChart++;
  const startDate = new Date();
  const numDays = this.indiceLineChart * 10;
  const subStartDate = this.substracDays(startDate, numDays);
  const nbDays = 10;
  const indice = 0;
  const idO = this.organisation._id;
  const idK = this.kiosk.idKiosk;
  this.updateChartLine(subStartDate,  this.nbDaysChartLine, idO, idK);
}

pushNext() {
  if (this.indiceLineChart !== 0) {
    this.indiceLineChart--;
    const startDate = new Date();
    const numDays = this.indiceLineChart * 10;
    const subStartDate = this.substracDays(startDate, numDays);
    const nbDays = 10;
    const indice = 0;
    const idO = this.organisation._id;
    const idK = this.kiosk.idKiosk;
    this.updateChartLine(subStartDate,  this.nbDaysChartLine, idO, idK);
}
  }


controlNextButton() {
  this.limitBackLineChart = false;
  if (this.indiceLineChart === 0) {
    this.limitNextLineChart = true;
  } else {
    this.limitNextLineChart = false;
  }
}

controlBackButton(campaigns) {
  let sumLength = 0;
  for (let i = 0; i < campaigns.length; i++) {
    sumLength += campaigns[i].dataPoints.length;
  }
  if (sumLength === 0) {
    this.limitBackLineChart = true;
    this.limitNextLineChart = false;
    this.indiceLineChart--;
  } else {
    this.currentDataChart = campaigns;
  }
  return this.limitBackLineChart;
}

callback() {
  if (this.userService.isLoggedIn()) {           
    this.dashboarddata.formatDataDashboardAll(this);
  } else {
    this.st.delTimer('5sec');
  }
}


}
