// import { Kiosk } from './kiosk.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class KioskService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  addKiosk(kioskValue) {
      return this.http.post(environment.apiBaseUrl + '/kiosk/add', kioskValue);
  }

  getKioks() {
    return this.http.get(environment.apiBaseUrl + '/kiosk/all');
  }

  getKioskOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/kiosk/' +id);
  }

  update(kioskValue) {
    return this.http.post(environment.apiBaseUrl + '/kiosk/update', kioskValue);
  }

  getKioksById(id) {
    return this.http.get(environment.apiBaseUrl + '/kiosk/' + id);
  }

  kioskByIdAndOrganisation(idO, idK) {
    return this.http.get(environment.apiBaseUrl + '/kiosk/transaction/' + idO + '/' + idK);
  }

  deleteOrganisationKiosk(id) {
    return this.http.get(environment.apiBaseUrl + '/kiosk/delete/organisation/' + id);
  }

  deleteKiosk(id) {
    return this.http.delete(environment.apiBaseUrl + '/kiosk/delete/' + id) ;
  }

  // getKioksByOrganisation(id) {
  //   return this.http.get(environment.apiBaseUrl + '/kiosk/organisation/' + id, this.noAuthHeader);
  // }

  getKiosksByOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/kiosks/organisation/transactions/' + id);
  }

  setTemplateKiosk(id,data) {
    return this.http.post(environment.apiBaseUrl + '/kiosk/update/template/' + id,data);
  }

  setDisplayNameKiosk(id,data) {
    return this.http.post(environment.apiBaseUrl + '/kiosk/update/displayName/' + id,data);
  }
  getTemplateKiosk() {
    return this.http.get(environment.apiBaseUrl + '/template');
  }

}
