import { Campaign } from './../../shared/campaign.model';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { OrganisationService } from '../../shared/organisation.service';
import { StripeTransactionService } from '../../shared/stripeTransaction.service';
import { Organisation } from '../../shared/organisation.model';
import { TransactionService } from '../../shared/transaction.service';
import * as CanvasJS from '../../../assets/canvasjs.min';

@Component({
  selector: 'app-chart-dashboard',
  templateUrl: './chart-dashboard.component.html',
  styleUrls: ['./chart-dashboard.component.css']
})
export class ChartDashboardComponent implements OnInit {

  nameOrganization: string;
  organisation: Organisation;
  refreshPage: Boolean = false;
  errorServer: Boolean = false;

  chart01: any;
  chart03: any;
  /**
   * Data pie chart
   */
  chartPie: any;
  dataPointChartDonut = [];
 /**
   * Data bar chart
   */
  limitBackBarChart: Boolean = false;
  limitNextBarChart: Boolean = true;
  chartBar: any;
  nbMonthChartBar: any = 5;
  dataPointChartBar = [];
  indiceBarChart: any = 0;
  /**
   * Data line chart
   */
  limitBackLineChart: Boolean = false;
  limitNextLineChart: Boolean = true;
  nbDaysChartLine: Number = 10;
  indiceLineChart: any = 0;
  dataLineChart: any;
  currentDataChart: any;



  constructor(private userService: UserService,
    private organisationService: OrganisationService,
    private transactionService: TransactionService,
    private stripeTransactionService: StripeTransactionService) {
    this.organisation = new Organisation();
  }

  refresh() {
    this.indiceLineChart = 0;
    this.indiceBarChart = 0;
    this.ngOnInit();
    this.refreshPage = true;
    setTimeout(() => {
      this.refreshPage = null;
    }, 2000);
  }

  ngOnInit() {
    this.userService.getUserProfile().subscribe(
      res => {
        this.getOrganisationByUserId(res['user'].idOrganisation);
      },
      err => {
        this.errorServer = true;
      });
  }

  getOrganisationByUserId(id) {
    this.organisationService.getOrganisationById(id).subscribe(
      res => {
        this.organisation = res['organisation'];
        this.stripeTransactionService.getSumCampaignTransactions(this.organisation._id).subscribe(
          res => {
            this.updateChartPie(res['campaign']);
            const startDate = new Date();
            this.updateChartLine(startDate, this.nbDaysChartLine, this.organisation._id);
            this.updateChartBar(startDate, this.nbMonthChartBar, this.organisation._id);
          },
          err => {
            this.errorServer = true;
          }
        );
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  /**
   * Chart pie
   * @param campaigns
   */
  updateChartPie(campaigns) {
    this.dataPointChartDonut = [];
    for (let i = 0; i < campaigns.length; i++) {
      this.dataPointChartDonut.push({
        y: campaigns[i].sumBrut,
        name: campaigns[i].title
      });
    }

    this.chartPie = new CanvasJS.Chart('chartContainerPie', {
      // theme: "dark1",
      exportFileName: 'Doughnut Chart',
      exportEnabled: true,
      animationEnabled: true,
      title: {
        text: 'Répartition totale par campagne'
      },
      legend: {
        cursor: 'pointer',
        itemclick: explodePie
      },
      data: [{
        type: 'pie',
        innerRadius: 90,
        // showInLegend: true,
        toolTipContent: '<b>{name}</b>: {y}€ (#percent%)',
        indexLabel: '{name} - #percent%',
        dataPoints: this.dataPointChartDonut
      }]
    });

    this.chartPie.render();

    function explodePie(e) {
      if (typeof (e.dataSeries.dataPoints[e.dataPointIndex].exploded) === 'undefined' || !e.dataSeries.dataPoints[e.dataPointIndex].exploded) {
        e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
      } else {
        e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
      }
      e.chart.render();
    }
  }

/**
 * CHART BAR
 */
updateChartBar(startDate, nbMonth, id) {
this.dataPointChartBar = [];
  this.stripeTransactionService.getSumCampaignTransactionsPerMonth(startDate, nbMonth, id).subscribe(
    res => {
      this.dataPointChartBar = res['transactions'];
      console.log(this.dataPointChartBar);
      this.chartBar = new CanvasJS.Chart('chartContainerBar', {
        animationEnabled: true,
        exportEnabled: true,
        // theme: "dark2", // "light1", "light2", "dark1", "dark2"
        title: {
          text: 'Revenu mensuel total'
        },
        axisY: {
          title: 'EUROS ( € )'
        },
        data: [{
          type: 'column',
          showInLegend: false,
          legendMarkerColor: 'grey',
          yValueFormatString: '#0.## €',
          indexLabel: '{y}',
          dataPoints : this.dataPointChartBar
        }]
      });
      this.chartBar.render();
    },
    err => {
      this.errorServer = true;
    });
}

pushBackBarChart() {
  this.indiceBarChart++;
  this.limitNextBarChart = false;
  const startDate = new Date();
  const nbMonth = this.indiceBarChart * this.nbMonthChartBar;
  const subStartDate = this.substractMonth(startDate, nbMonth);
  const id = this.organisation._id;
  this.updateChartBar(subStartDate,  this.nbMonthChartBar, id);
}

pushNextBarChart() {
  this.indiceBarChart--;
  if (this.indiceBarChart !== 0) {
    const startDate = new Date();
    const nbMonth = this.indiceBarChart * this.nbMonthChartBar;
    const subStartDate = this.substractMonth(startDate, nbMonth);
    this.updateChartBar(subStartDate, this.nbMonthChartBar, this.organisation._id);
  } else {
    this.limitNextBarChart = true;
    const startDate = new Date();
    const nbMonth = this.indiceBarChart * this.nbMonthChartBar;
    this.updateChartBar(startDate, this.nbMonthChartBar, this.organisation._id);
  }
}

substractMonth(date, days) {
  const copy = new Date(Number(date));
  copy.setMonth(date.getMonth() - days);
  return copy;
}

/**
 * Chart line
 * @param starDate
 * @param nbDays
 * @param id
 */
  updateChartLine(starDate, nbDays, id) {
    const that = this;
    this.stripeTransactionService.getSumTransactionsByCampaignPerDay(starDate, nbDays, id).subscribe(
      res => {
        this.controlNextButton();
        this.controlBackButton(res['campaign']);
        this.dataLineChart = res['campaign'];
        this.chart03 = new CanvasJS.Chart('chartContainer02', {
          animationEnabled: true,
          exportEnabled: true,
          title: {
            text: 'Collecte jour par campagne'
          },
          axisY: {
            title: 'EURO ( € )'
          },
          toolTip: {
            shared: true
          },
          legend: {
            cursor: 'pointer',
            itemclick: toggleDataSeries
          },
          data: this.currentDataChart
        });
        this.chart03.render();
        function toggleDataSeries(e) {
          if (typeof (e.dataSeries.visible) === 'undefined' || e.dataSeries.visible) {
            e.dataSeries.visible = false;
          } else {
            e.dataSeries.visible = true;
          }
          that.chart03.render();
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }

substracDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() - days);
    return copy;
  }

  pushBack() {
    this.indiceLineChart++;
    const startDate = new Date();
    const numDays = this.indiceLineChart * 10;
    const subStartDate = this.substracDays(startDate, numDays);
    const nbDays = 10;
    const indice = 0;
    const id = this.organisation._id;
    this.updateChartLine(subStartDate,  this.nbDaysChartLine, id);
  }

  pushNext() {
    if (this.indiceLineChart !== 0) {
      this.indiceLineChart--;
      const startDate = new Date();
      const numDays = this.indiceLineChart * 10;
      const subStartDate = this.substracDays(startDate, numDays);
      const nbDays = 10;
      const indice = 0;
      const id = this.organisation._id;
      this.updateChartLine(subStartDate, this.nbDaysChartLine, id);
    }
  }

  controlNextButton() {
    this.limitBackLineChart = false;
    if (this.indiceLineChart === 0) {
      this.limitNextLineChart = true;
    } else {
      this.limitNextLineChart = false;
    }
  }

  controlBackButton(campaigns) {
    let sumLength = 0;
    for (let i = 0; i < campaigns.length; i++) {
      sumLength += campaigns[i].dataPoints.length;
    }
    if (sumLength === 0) {
      this.limitBackLineChart = true;
      this.limitNextLineChart = false;
      this.indiceLineChart--;
    } else {
      this.currentDataChart = campaigns;
    }
    return this.limitBackLineChart;
  }



}
