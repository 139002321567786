import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KioskService } from './../../../shared/kiosk.service';
import { Organisation } from './../../../shared/organisation.model';
import { Kiosk } from './../../../shared/kiosk.model';
import { NgForm, FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';
import { OrganisationService } from './../../../shared/organisation.service';


@Component({
  selector: 'app-updatekioskadmin',
  templateUrl: './updatekioskadmin.component.html',
  styleUrls: ['./updatekioskadmin.component.css']
})
export class UpdatekioskadminComponent implements OnInit {

  organisationCurrent: Organisation;
  isOrganisationSelected: Boolean = false;
  form: FormGroup;
  fb: FormBuilder;
  organisation = [];
  Kiosk: Kiosk;
  lastIdOrganisation: String;

  constructor(private organisationService: OrganisationService, private route: ActivatedRoute,
              private kioskService: KioskService, fb: FormBuilder) {
      this.form = fb.group({
        id: [''],
        id_kiosk: [''],
        type: ['', Validators.required],
        supervisor: ['', Validators.required],
        line01: ['', Validators.required],
        city: ['', Validators.required],
        postaleCode: ['', Validators.required],
        state: ['', Validators.required],
        location: ['', Validators.required],
        description: ['', Validators.required],
        organisationName: ['']
        });
  }

  ngOnInit() {

    this.kioskService.getKioksById(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
          this.Kiosk = res['kiosk'];

        if (res['kiosk'].idOrganisation !== null) {
          console.log('je suis dans la condition');
          console.log(res);
          this.isOrganisationSelected = true;
          this.organisationCurrent = res['organisation'];
          }
          this.form.controls['id'].setValue(res['kiosk']._id);
          this.form.controls['id_kiosk'].setValue(res['kiosk'].id_kiosk);
          this.form.controls['type'].setValue(res['kiosk'].type);
          this.form.controls['supervisor'].setValue(res['kiosk'].supervisor);
          this.form.controls['line01'].setValue(res['kiosk'].address.line01);
          this.form.controls['city'].setValue(res['kiosk'].address.city);
          this.form.controls['postaleCode'].setValue(res['kiosk'].address.postaleCode);
          this.form.controls['state'].setValue(res['kiosk'].address.state);
          this.form.controls['location'].setValue(res['kiosk'].location);
          this.form.controls['description'].setValue(res['kiosk'].description);

      },
      err => {
          this.Kiosk = err['error'].kiosk;
          this.form.controls['id'].setValue(err['error'].kiosk._id);
          this.form.controls['id_kiosk'].setValue(err['error'].kiosk.id_kiosk);
          this.form.controls['type'].setValue(err['error'].kiosk.type);
          this.form.controls['supervisor'].setValue(err['error'].kiosk.supervisor);
          this.form.controls['line01'].setValue(err['error'].kiosk.address.line01);
          this.form.controls['city'].setValue(err['error'].kiosk.address.city);
          this.form.controls['postaleCode'].setValue(err['error'].kiosk.address.postaleCode);
          this.form.controls['state'].setValue(err['error'].kiosk.address.state);
          this.form.controls['location'].setValue(err['error'].kiosk.location);
          this.form.controls['description'].setValue(err['error'].kiosk.description);
      }

    );
  }

  listOrganisations() {
    this.organisationService.getAllOrganisation().subscribe(
      res => {
        this.organisation = res['organisation'];
      },
      err => {
      }
      );
  }

  addOrganisation(orgnaisation) {
    this.isOrganisationSelected = true;
    this.organisationCurrent = orgnaisation;
    this.form.controls['organisationName'].setValue(orgnaisation.organisationName);
  }

  deleteSelectedOrganisation() {
    this.kioskService.deleteOrganisationKiosk(this.form.value.id).subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err);
      }
    );
    this.organisationCurrent.organisationName = '';
    this.organisationCurrent.accountStripe.email = '';
    this.isOrganisationSelected = false;
    this.organisationCurrent = null;
    this.form.controls['organisationName'].setValue('Aucune Organisation');
  }

  onSubmit() {

    console.log(this.form);
     /**
      * Define if organisation selected
      */
      this.form.value.isOrganisationSelected = this.isOrganisationSelected;
      this.form.controls['organisationName'].setValue('Aucune Organisation');
      /**
       * If organisation selected for kiosk
       */
      if (this.isOrganisationSelected === true) {
          this.form.value.organisation = this.organisationCurrent;
          this.form.controls['organisationName'].setValue(this.organisationCurrent.organisationName);
          // this.form.value.idOrgnaisation = this.organisationCurrent._id;
        }
      this.form.value.organisation = this.organisationCurrent;
      /**
       * If the form is valid ( all informations valid )
       */
      if (this.form.status === 'VALID') {
        this.form.value.idLastOrganisation = this.lastIdOrganisation;
        this.form.value.isOrganisationSelected = this.isOrganisationSelected;
        /**
         * Update kiosk by id
         */
        this.kioskService.update(this.form.value).subscribe(
          res => {
            console.log(res);
          },
          err => {
            console.log(err);
          }
        );
      }
  }

}
