import { Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardmenuComponent } from './dashboard/dashboardmenu/dashboardmenu.component';
import { ComfirmAccountComponent } from './comfirm-account/comfirm-account.component';
import { DetailsAccountComponent } from './details-account/details-account.component';
import { TransactionsComponent } from './dashboard/transactions/transactions.component';
import { HomeDashboardComponent } from './dashboard/home-dashboard/home-dashboard.component';
import { HomeDashboardMonthComponent } from './dashboard/home-dashboard_month/home-dashboard_month.component';
import { HomeDashboardYearComponent } from './dashboard/home-dashboard-year/home-dashboard-year.component';
import { HomeDashboardTodayComponent } from './dashboard/home-dashboard-today/home-dashboard-today.component';
import { KioskComponent } from './dashboard/kiosk/kiosk.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { OrganizationComponent } from './dashboard/organization/organization.component';
import { AuthGuard } from './auth/auth.guard';
/*** Admin dashboard */
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { HomeDashboardAdminComponent } from './dashboard-admin/home-dashboard-admin/home-dashboard-admin.component';
import { KioskdAdminComponent } from './dashboard-admin/kioskd-admin/kioskd-admin.component';
import { CreatekioskadminComponent } from './dashboard-admin/kioskd-admin/createkioskadmin/createkioskadmin.component';
import { ListkioskadminComponent } from './dashboard-admin/kioskd-admin/listkioskadmin/listkioskadmin.component';
import { UpdatekioskadminComponent } from './dashboard-admin/kioskd-admin/updatekioskadmin/updatekioskadmin.component';
import { OrganisationAdminComponent } from './dashboard-admin/organisation-admin/organisation-admin.component';
import { ListOrganisationAdminComponent } from './dashboard-admin/organisation-admin/list-organisation-admin/list-organisation-admin.component';
import { CreateOrganisationAdminComponent } from './dashboard-admin/organisation-admin/create-organisation-admin/create-organisation-admin.component';
import { UpdateOrganisationAdminComponent } from './dashboard-admin/organisation-admin/update-organisation-admin/update-organisation-admin.component';
import { ReadOrganisationAdminComponent } from './dashboard-admin/organisation-admin/read-organisation-admin/read-organisation-admin.component';
import { ListKioskOrganisationAdminComponent } from './dashboard-admin/organisation-admin/list-kiosk-organisation-admin/list-kiosk-organisation-admin.component';
import { DonationCampaignAdminComponent } from './dashboard-admin/donation-campaign-admin/donation-campaign-admin.component';
import { ListDonationCampaignAdminComponent } from './dashboard-admin/donation-campaign-admin/list-donation-campaign-admin/list-donation-campaign-admin.component';
import { InitPasswordComponent } from './user/init-password/init-password.component';
import { ExternalAccountStripeComponent } from './dashboard/external-account-stripe/external-account-stripe.component';
import { UploadIdentityComponent } from './dashboard/upload-identity/upload-identity.component';
import { CampaignComponent } from './dashboard/campaign/campaign.component';
import { ListcampaignComponent } from './dashboard/campaign/listcampaign/listcampaign.component';
import { CreatecampaignComponent } from './dashboard/campaign/createcampaign/createcampaign.component';
import { UpdatecampaignComponent } from './dashboard/campaign/updatecampaign/updatecampaign.component';
import { ReadcampaignComponent } from './dashboard/campaign/readcampaign/readcampaign.component';
import { ListTransactionComponent } from './dashboard/transactions/list-transaction/list-transaction.component';
import { ReadTransactionComponent } from './dashboard/transactions/read-transaction/read-transaction.component';
import { TransactionCampaignComponent } from './dashboard/campaign//transaction-campaign/transaction-campaign.component';
import { ChartDashboardComponent } from './dashboard/chart-dashboard/chart-dashboard.component';
import { DonatorComponent } from './dashboard/donator/donator.component';
import { DonatorListComponent } from './dashboard/donator/donator-list/donator-list.component';
import { FiscalReceiptDonatorComponent } from './dashboard/donator/fiscal-receipt-donator/fiscal-receipt-donator.component';
import { TransferComponent } from './dashboard/transfer/transfer.component';
import { TransferListComponent } from './dashboard/transfer/transfer-list/transfer-list.component';
import { TransactionDonatorComponent } from './dashboard/donator/transaction-donator/transaction-donator.component';
import { ListKioskComponent } from './dashboard/kiosk/list-kiosk/list-kiosk.component';
import { ReadKioskComponent } from './dashboard/kiosk/read-kiosk/read-kiosk.component';
import { TemplateKioskComponent } from './dashboard/kiosk/template-kiosk/template-kiosk.component';
import { TransactionKioskComponent } from './dashboard/kiosk/transaction-kiosk/transaction-kiosk.component';
import { UpdatePasswordUserComponent } from './user/update-password-user/update-password-user.component';
import { DonationCardComponent } from './donation-card/donation-card.component';



export const appRoutes: Routes = [

  {
    path: 'signup', component: UserComponent,
    children: [
      { path: '', component: SignUpComponent }
    ]
  },
  {
    path: 'login', component: UserComponent,
    children: [
      { path: '', component: SignInComponent }
    ]
  },
  {
    path: 'password', component: UserComponent,
    children: [
      { path: '', component: InitPasswordComponent }
    ]

  },
  {
    path: 'update/password/user/:id', component: UserComponent,
    children: [
      { path: '', component: UpdatePasswordUserComponent }
    ],
  },
  {
    path: 'confirmaccount/:id', component: ComfirmAccountComponent
  },

  {
    path: 'userprofile', component: UserProfileComponent, canActivate: [AuthGuard]
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      // { path: 'dashboard/confirme/compte', component: ComfirmAccountComponent, canActivate: [AuthGuard] },
      { path: '', component: HomeDashboardComponent, canActivate: [AuthGuard] },
      { path: 'month', component: HomeDashboardMonthComponent, canActivate: [AuthGuard] },
      { path: 'year', component: HomeDashboardYearComponent, canActivate: [AuthGuard] },
      { path: 'today', component: HomeDashboardTodayComponent, canActivate: [AuthGuard] },

      { path: 'chart', component: ChartDashboardComponent, canActivate: [AuthGuard] },
      {
        path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: ListTransactionComponent, canActivate: [AuthGuard] },
          { path: 'read/:id', component: ReadTransactionComponent, canActivate: [AuthGuard] },
        ]
      },
      {
        path: 'kiosk', component: KioskComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: ListKioskComponent, canActivate: [AuthGuard] },
          { path: 'read/:id', component: ReadKioskComponent, canActivate: [AuthGuard] },
          { path: 'transaction/:id', component: TransactionKioskComponent, canActivate: [AuthGuard] },
          { path: 'template/:id', component: TemplateKioskComponent, canActivate: [AuthGuard] },
        ]
      },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'organization', component: OrganizationComponent, canActivate: [AuthGuard] },
      { path: 'externalaccountstripecomponent', component: ExternalAccountStripeComponent, canActivate: [AuthGuard] },
      { path: 'uploadidentity', component: UploadIdentityComponent, canActivate: [AuthGuard] },
      {
        path: 'campaign', component: CampaignComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: ListcampaignComponent, canActivate: [AuthGuard] },
          { path: 'create', component: CreatecampaignComponent, canActivate: [AuthGuard] },
          { path: 'update', component: UpdatecampaignComponent, canActivate: [AuthGuard] },
          { path: 'read/:id', component: ReadcampaignComponent, canActivate: [AuthGuard] },
          { path: 'transaction/:id', component: TransactionCampaignComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'donator', component: DonatorComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: DonatorListComponent, canActivate: [AuthGuard] },
          { path: 'transaction/:id', component: TransactionDonatorComponent, canActivate: [AuthGuard] },
          { path: 'transaction/fiscal/receipt/:id', component: FiscalReceiptDonatorComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'transfer', component: TransferComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: TransferListComponent, canActivate: [AuthGuard] },
        ]
      },

      // { path: '', component: DashboardmenuComponent },
      // {path: '', redirectTo: '/dashboard', pathMatch: 'full'}
    ]
  },
  {
    path: 'dashboard/admin', component: DashboardAdminComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeDashboardAdminComponent, canActivate: [AuthGuard] },
      {
        path: 'kiosk', component: KioskdAdminComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: ListkioskadminComponent, canActivate: [AuthGuard] },
          { path: 'create', component: CreatekioskadminComponent, canActivate: [AuthGuard] },
          { path: 'update/:id', component: UpdatekioskadminComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'organisation', component: OrganisationAdminComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: ListOrganisationAdminComponent, canActivate: [AuthGuard] },
          { path: 'create', component: CreateOrganisationAdminComponent, canActivate: [AuthGuard] },
          { path: 'update/:id', component: UpdateOrganisationAdminComponent, canActivate: [AuthGuard] },
          { path: 'read/:id', component: ReadOrganisationAdminComponent, canActivate: [AuthGuard] },
          { path: 'kiosks/:id', component: ListKioskOrganisationAdminComponent, canActivate: [AuthGuard] },

        ]
      },
      { path: 'campaign', component: DonationCampaignAdminComponent, canActivate: [AuthGuard] }

    ]
  },
  {
    path: 'account/details', component: DetailsAccountComponent, canActivate: [AuthGuard],
  },
  {
    path: '**', redirectTo: '/login', pathMatch: 'full'
  }
];
