import { NgForm, FormGroup, Validators, FormBuilder, Form  } from '@angular/forms';
import { PasswordValidation,MustMatch } from '../../tools/PasswordValidation';
import { KioskComponent } from './../kiosk/kiosk.component';
import { Organisation } from './../../shared/organisation.model';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { CustomValidators } from '../../_helpers/custom-validators';
import { OrganisationService } from './../../shared/organisation.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {
  
  messageSucessPasswordIsVisible: Boolean = false;
  messageErrorPasswordIsVisible: Boolean = false;
  form: FormGroup;
  maxDate = '';
  datetime: Date;
  maxDateYear: number;
  userDetails = {};

  passwordObject = {
    password: '',
    confirmPassword: ''
  };

  user = {
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  }

  accountDetails =
    {
      id: '',
      email: '',
      organisationName: '',
      business_type: 'individual',
      first_name: '',
      last_name: '',
      phone: '',
      dobindividual: '',
      iban : '',
      dob: {
        day: '00',
        month: '00',
        year: '0000',
      },
      address: {
        city: '',
        line1: '',
        postal_code: '',
        state: ''
      },
    };

  organisation: Organisation;
  status: Boolean;

  // constructor(private userService: UserService, private fb: FormBuilder) {
  constructor(private userService: UserService, private fb: FormBuilder, private organisationService: OrganisationService) {
    // this.frmSignup = this.createSignupForm();

    this.form = this.fb.group({
      // define your control in you form
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword') // your validation method
    });
  }
  get registerFormControl() {
    return this.form.controls;
  }

  ngOnInit() {

    this.userService.getUserProfile().subscribe(
      res => {
        console.log(res['user']);
        this.accountDetails.id = res['user']._id;
        this.accountDetails.email = res['user'].email;
        this.getOrganisationByUserId(res['user'].idOrganisation);

      },
      err => {
        console.log(err);
      }
    );

    // Initialisation Date for date of birth creation account
    const dateTime = new Date();
    this.datetime = new Date();
    const date = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Be careful! January is 0 not 1
    const year = dateTime.getFullYear() - 18;
    this.maxDateYear = dateTime.getFullYear() - 18;
    if (month < 10) {
      this.maxDate = year + '-0' + month + '-' + date;
    } else {
      this.maxDate = year + '-' + month + '-' + date;
    }
  }
  onSubmit() {
    if (this.form.status === 'VALID') {


      // Get id user
      var objectUser = {
        id: this.accountDetails.id,
        password: this.form.value.password
      }

      this.userService.updatePassword(objectUser).subscribe(
        res => {
          console.log(res);
          this.messageSucessPasswordIsVisible = true;
          setTimeout(() => this.messageSucessPasswordIsVisible = false, 4000);
          this.form.reset();
          //  this.resetForm(form);
        },
        err => {
          this.messageErrorPasswordIsVisible = true;
          setTimeout(() => this.messageErrorPasswordIsVisible = false, 4000);
          this.form.reset();
        }
      );

    } else {
      // console.log("Stop no match password :(");
    }
  }


  getOrganisationByUserId(id) {
    this.organisationService.getOrganisationById(id).subscribe(
      res => {
        console.log(res);
        this.organisation = res['organisation'];
        this.accountDetails.first_name = res['organisation'].president.first_name;
        this.accountDetails.last_name = res['organisation'].president.last_name;
        this.accountDetails.phone = '+33 (0)' + res['organisation'].president.phone;
        this.accountDetails.dobindividual = this.formatDate(new Date(res['organisation'].president.dobindividual));
        this.status = true;
      },
      err => {
        this.status = false;
      }
    );
  }


  formatDate(date) {
    const days = date.getDate();
    const month = date.getMonth() + 1; // Be careful! January is 0 not 1
    const year = date.getFullYear();
    if (month < 10) {
      return '0' + days + '-0' + month + '-' + year;
    } else {
      return days + '-' + month + '-' + year;
    }
  }


  // save(model: Object, isValid: boolean) {
  //   // call API to save customer
  //   console.log(model, isValid);
  // }

  // public frmSignup: FormGroup;
  // createSignupForm(): FormGroup {
  //   return this.fb.group(
  //     {

  //       password: [
  //         null,
  //         Validators.compose([
  //           Validators.required,
  //           // check whether the entered password has a number
  //           CustomValidators.patternValidator(/\d/, {
  //             hasNumber: true
  //           }),
  //           // check whether the entered password has upper case letter
  //           CustomValidators.patternValidator(/[A-Z]/, {
  //             hasCapitalCase: true
  //           }),
  //           // check whether the entered password has a lower case letter
  //           CustomValidators.patternValidator(/[a-z]/, {
  //             hasSmallCase: true
  //           }),
  //           // check whether the entered password has a special character
  //           CustomValidators.patternValidator(
  //             /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
  //             {
  //               hasSpecialCharacters: true
  //             }
  //           ),
  //           Validators.minLength(8)
  //         ])
  //       ],
  //       confirmPassword: [null, Validators.compose([Validators.required])]
  //     },
  //     {
  //       // check whether our password and confirm password match
  //       validator: CustomValidators.passwordMatchValidator
  //     }
  //   );
  // }
  // submit() {
  //   // do signup or something
  //   console.log(this.frmSignup.value);
  // }

}
// https://github.com/mainawycliffe/ng-bootstrap-password-validation-example/blob/master/src/app/app.component.html
