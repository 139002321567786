import { Component, OnInit } from '@angular/core';
import { Transaction } from '../../../shared/transaction.model';
import { TransactionService } from '../../../shared/transaction.service';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { UserService } from '../../../shared/user.service';
import { PagerService } from '../../../shared/pager.service';
import { OrganisationService } from '../../../shared/organisation.service';
import { Organisation } from '../../../shared/organisation.model';
import { KpiService } from '../../../shared/kpi.service';
import {Dashboarddata} from '../../../shared/dashaboardata';
import  { formatter } from '../../../shared/formatter';
import { Router } from '@angular/router';
import { CountUp } from "countup.js";
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-list-transaction',
  templateUrl: './list-transaction.component.html',
  styleUrls: ['./list-transaction.component.css']
})
export class ListTransactionComponent implements OnInit  {

  formartter : formatter ; 
  dashboarddata : Dashboarddata;
  modelDashboard : any;
  idOrganization: String;
  transactionArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  listTransaction: Boolean;
  currentTransaction: Transaction;
  errorMsg;
  validEmail;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  email: String = '';

  isEmailSended;
  isEmailNotSend;
  isEmailSending: Boolean = false;

  organization: Organisation;

  totalDonationBrut: any = 0;
  totalDonationNet: any = 0;
  totalDonationFee: any = 0;
  
  constructor(private transactionService: TransactionService,
              private stripeTransactionService: StripeTransactionService,
              private userService: UserService,
              private pagerService: PagerService,
              private organisationService: OrganisationService,  
              private kpiService : KpiService,
              private router: Router) {
                this.formartter = new formatter();
                this.dashboarddata = new Dashboarddata(userService,transactionService,stripeTransactionService,organisationService,kpiService);
                this.modelDashboard = this.dashboarddata.modelDashboard;
               }

  ngOnInit() {
    var that = this;
    this.userService.getUserProfile().subscribe(
      res => {
        this.getOrganisationByUserId(res['user'].idOrganisation);
        this.list(res['user'].idOrganisation);
        this.idOrganization = res['user'].idOrganisation;
        setTimeout(function(){ that.callback(); }, Dashboarddata.delay);        
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  getOrganisationByUserId(id) {
   

    this.organisationService.getOrganisationById(id).subscribe(
      res => {
       
          this.organization = res['organisation'];
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  calculTransaction(transactions) {
    
    for (let i = 0; i < transactions.length; i++) {
      if (transactions[i].fee !== undefined && !isNaN(Number(transactions[i].fee))){
        this.totalDonationFee += Number(transactions[i].fee);
      }

      if (transactions[i].net !== undefined && !isNaN(Number(transactions[i].net))){
        this.totalDonationNet += Number(transactions[i].net);
      }
      if (transactions[i].amount !== undefined && !isNaN(Number(transactions[i].amount))){
        this.totalDonationBrut += Number(transactions[i].amount);
      }

     
    }

    this.totalDonationFee = this.totalDonationFee.toFixed(2);
    this.totalDonationNet = this.totalDonationNet.toFixed(2);
    this.totalDonationBrut = this.totalDonationBrut.toFixed(2);
  }


  list(id) {

    this.stripeTransactionService.transactionStripeByOrganisation(id).subscribe(
      res => {
        this.calculTransaction(res['transactions']);
        this.transactionArray = res['transactions'];
        this.allItems = res['transactions'];

        this.transactionArray.reverse();

        if (this.allItems.length !== 0) {
          this.listTransaction = true;
        } else {
          this.listTransaction = false;
        }
        this.setPage(1);
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  searchCampaign(searchValue: string) {
    const campaignArray = this.transactionArray.filter(x => x.idStripe.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = campaignArray;
    this.setPage(1);
  }

  selectCurrentCampaign(transaction) {
    this.currentTransaction = transaction;
  }
  switchKiosk(event){

    console.log(event);

  }

  selectTransaction(transaction) {
    this.isEmailSending = false;
    this.isEmailNotSend = null;
    this.isEmailSended = null;
    this.validEmail = null;
    this.email = '';
    if (transaction.email !== 'null') {
      this.email = transaction.email;
    }
    this.currentTransaction = transaction;
    this.currentTransaction['organizationName'] = this.organization.organisationName;
  }

  sendEmailReceipt() {

    if (this.emailRegex.test(String(this.email).toLowerCase()) === true) {
      this.validEmail = null;
      this.isEmailSending = true;
      // SEND EMAIL
      this.currentTransaction['email'] = this.email;
      this.transactionService.sendReceiptTransaction(this.currentTransaction).subscribe(
        res => {
          this.isEmailSending = null;
          this.isEmailSended = true;
        },
        err => {
          this.isEmailSending = null;
          this.isEmailNotSend = true;
        }
      );
    } else {
      this.validEmail = false;
      setTimeout(() => {
        this.validEmail = null;
      }, 3000);
    }

  }

  callback() {
    if (this.userService.isLoggedIn()) {           
      this.dashboarddata.formatDataDashboardAll(this);
    } else {
     
    }
  }

}
