import { Component, OnInit } from '@angular/core';
import { PagerService } from '../../../shared/pager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { TransactionService } from '../../../shared/transaction.service';
import { StripeTransaction } from '../../../shared/stripeTransaction.model';
import { UserService } from '../../../shared/user.service';
import { Transaction } from '../../../shared/transaction.model';

@Component({
  selector: 'app-transaction-donator',
  templateUrl: './transaction-donator.component.html',
  styleUrls: ['./transaction-donator.component.css']
})
export class TransactionDonatorComponent implements OnInit {

  errorServer: boolean;
  transactionsArray = [];
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  listTransaction: Boolean;
  idDonator: any;

  transaction: StripeTransaction;
  currentTransaction: Transaction;

  validEmail;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  email: String = '';
  isEmailSended;
  isEmailNotSend;
  isEmailSending: Boolean = false;


  constructor(private transactionService: TransactionService,
    private stripeTransactionService: StripeTransactionService,
    private route: ActivatedRoute,
    private userService: UserService,
    private pagerService: PagerService) { }

  ngOnInit() {
    this.userService.getUserProfile().subscribe(
      res => {
        this.idDonator = this.route.snapshot.paramMap.get('id');
        this.getTransactionOrganisationByEmail(res['user'].idOrganisation, this.route.snapshot.paramMap.get('id'));
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  getTransactionOrganisationByEmail(id, email) {
    this.stripeTransactionService.getStripeTransactionOrganisationByEmail(id, email).subscribe(
      res => {
        console.log(res['transactions']);
        this.transactionsArray = res['transactions'];
        this.allItems = res['transactions'];
        this.transactionsArray.reverse();
        if (this.allItems.length !== 0) {
          this.listTransaction = true;
        } else {
          this.listTransaction = false;
        }
        this.setPage(1);
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  searchTransaction(searchValue: string) {
    const transactionsArray = this.transactionsArray.filter(x => x.idStripe.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = transactionsArray;
    this.setPage(1);
  }

  updateCurrentTransaction(transaction) {
    this.validEmail = null;
    this.email = '';
    this.isEmailSended = null;
    this.isEmailNotSend = null;
    this.isEmailSending = false;
    if (transaction.email !== 'null') {
      this.email = transaction.email;
    }
    this.transaction = transaction;
    this.currentTransaction = transaction;
  }

  sendEmailReceipt_old() {

    if (this.emailRegex.test(String(this.email).toLowerCase()) === true) {
      this.validEmail = null;
      this.isEmailSending = true;
      // SEND EMAIL
      //  this.currentTransaction['email'] = this.email;
      //  this.transactionService.sendReceiptTransaction(this.currentTransaction).subscribe(
      //    res => {
      //      this.isEmailSending = null;
      //      this.isEmailSended = true;
      //    },
      //    err => {
      //      this.isEmailSending = null;
      //      this.isEmailNotSend = true;
      //    }
      //  );
    } else {
      this.validEmail = false;
      setTimeout(() => {
        this.validEmail = null;
      }, 3000);
    }

  }



  sendEmailReceipt() {

    if (this.emailRegex.test(String(this.email).toLowerCase()) === true) {
      this.validEmail = null;
      this.isEmailSending = true;
      // SEND EMAIL
      this.currentTransaction['email'] = this.email;
      this.transactionService.sendReceiptTransaction(this.currentTransaction).subscribe(
        res => {
          this.isEmailSending = null;
          this.isEmailSended = true;
        },
        err => {
          this.isEmailSending = null;
          this.isEmailNotSend = true;
        }
      );
    } else {
      this.validEmail = false;
      setTimeout(() => {
        this.validEmail = null;
      }, 3000);
    }

  }

}
